import { useFormatCurrency } from '@teamsesam/core'
import { useCallback } from 'react'

export const useFormatPrice = () => {
  const formatCurrency = useFormatCurrency({ hideSymbol: true })

  return useCallback(
    (price: number): string => {
      if (price < 0) {
        return 'auf Anfrage'
      }
      return formatCurrency(price)
    },
    [formatCurrency],
  )
}
