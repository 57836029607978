import { ItemContext } from '@teamsesam/configurator-core'
import { ItemProperties, SideWallType } from '../config'
import * as THREE from 'three'
import { holeRadius, plateThickness } from '../constants'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils'
import { defaultOuterShadowOptions, OuterShadow } from '../shadows/outer/outerShadow'

const createShape = (height: number, depth: number): THREE.Shape => {
  const wall = new THREE.Shape().moveTo(0, 0).lineTo(depth, 0).lineTo(depth, height).lineTo(0, height).lineTo(0, 0)
  let y = 5
  let holeRowsUntilGap = 3
  let addedHoleRows = 0
  while (y <= height) {
    if (addedHoleRows === holeRowsUntilGap) {
      holeRowsUntilGap = holeRowsUntilGap === 3 ? 4 : 3
      addedHoleRows = 0
    } else {
      let x = 4.5
      while (x <= depth) {
        wall.holes.push(new THREE.Path().absarc(x, y, holeRadius, 0, 2 * Math.PI, false))
        x += 13.5
      }
      addedHoleRows++
    }
    y += 3
  }
  return wall
}

const createGeomtry = (height: number, depth: number, type: SideWallType) => {
  const shape = createShape(height, depth)
  const holesGeometry = new THREE.ExtrudeGeometry(shape, {
    depth: type === SideWallType.Middle ? plateThickness : plateThickness / 2,
    bevelEnabled: false,
  })
  holesGeometry.rotateY(Math.PI / 2)
  holesGeometry.center()
  if (type === SideWallType.Middle) {
    return holesGeometry
  }
  const wallGeometry = new THREE.BoxGeometry(plateThickness / 2, height, depth)
  const translateX = type === SideWallType.Left ? plateThickness / -4 : plateThickness / 4
  wallGeometry.translate(translateX, 0, 0)
  holesGeometry.translate(-translateX, 0, 0)
  const wallWithSideHolesGeometry = BufferGeometryUtils.mergeBufferGeometries([wallGeometry.toNonIndexed(), holesGeometry])
  return new THREE.BufferGeometryLoader().parse(wallWithSideHolesGeometry.toJSON())
}

export const sideWall = (context: ItemContext) => {
  const { item, on, materials } = context

  let wall: THREE.Mesh
  let currentMaterial = materials
  let shadow: OuterShadow | undefined

  const updateWall = () => {
    if (wall) {
      item.remove(wall)
    }
    const wallHeight = item.getPropertyValue(ItemProperties.SideWallHeight)
    const wallDepth = item.getPropertyValue(ItemProperties.ShelfDepth)
    const type = item.getPropertyValue(ItemProperties.SideWallType) as SideWallType
    wall = new THREE.Mesh(createGeomtry(wallHeight, wallDepth, type), currentMaterial.shelfMaterial)
    item.add(wall)

    if (type === SideWallType.Left) {
      if (!shadow) {
        const { intensity, wallLeft, outerLeft } = defaultOuterShadowOptions
        shadow = new OuterShadow({ intensity, wallLeft, outerLeft })
        item.add(shadow)
      }
    } else if (type === SideWallType.Right) {
      if (!shadow) {
        const { intensity, wallRight, outerRight } = defaultOuterShadowOptions
        shadow = new OuterShadow({ intensity, wallRight, outerRight })
        item.add(shadow)
      }
    } else {
      if (shadow) {
        item.remove(shadow)
        shadow = undefined
      }
    }

    if (shadow) {
      shadow.setSize(new THREE.Vector3(plateThickness, wallHeight, wallDepth))
    }

    item.updateBoundingBox()
  }

  updateWall()

  on.propertyChanged([ItemProperties.ShelfDepth, ItemProperties.SideWallHeight, ItemProperties.SideWallType], () => updateWall())

  on.materialsChanged((materials) => {
    wall.material = materials.shelfMaterial
    currentMaterial = materials
  })
}
