import * as THREE from 'three'
import _ from 'lodash'
import { ShadowMesh, ShadowTexureForUvUpdateOptions } from '../shadowMesh'
import InnerLeftTexture from '../../../assets/shadows/innerLeft.png'
import InnerRightTexture from '../../../assets/shadows/innerRight.png'
import InnerBottomTexture from '../../../assets/shadows/innerBottom.png'
import InnerTopTexture from '../../../assets/shadows/innerTop.png'
import InnerRearTexture from '../../../assets/shadows/innerRear.png'
import { InnerLeftShadow } from './innerLeftShadow'
import { InnerBottomShadow } from './innerBottomShadow'
import { InnerRearShadow } from './innerRearShadow'
import { InnerRightShadow } from './innerRightShadow'
import { InnerTopShadow } from './innerTopShadow'
import { TextureCache } from '../textureCache'

export interface InnerShadowOptions {
  innerLeft?: ShadowTexureForUvUpdateOptions
  innerRight?: ShadowTexureForUvUpdateOptions
  innerTop?: ShadowTexureForUvUpdateOptions
  innerBottom?: ShadowTexureForUvUpdateOptions
  innerRear?: ShadowTexureForUvUpdateOptions
}

TextureCache.appendToDefault([InnerRearTexture, InnerLeftTexture, InnerRightTexture, InnerTopTexture, InnerBottomTexture])

const textureWidthAndHeight = 100

export const defaultInnerShadowOptions = {
  innerLeft: {
    texture: InnerLeftTexture,
    realWorldSize: new THREE.Vector2(textureWidthAndHeight, textureWidthAndHeight),
  },
  innerRight: {
    texture: InnerRightTexture,
    realWorldSize: new THREE.Vector2(textureWidthAndHeight, textureWidthAndHeight),
  },
  innerTop: {
    texture: InnerTopTexture,
    realWorldSize: new THREE.Vector2(textureWidthAndHeight, textureWidthAndHeight),
  },
  innerBottom: {
    texture: InnerBottomTexture,
    realWorldSize: new THREE.Vector2(textureWidthAndHeight, textureWidthAndHeight),
  },
  innerRear: {
    texture: InnerRearTexture,
    realWorldSize: new THREE.Vector2(textureWidthAndHeight, textureWidthAndHeight),
  },
}

export class InnerShadow extends THREE.Object3D {
  private shadows: ShadowMesh[] = []
  private _hasTopShadow = true
  private topShadow: ShadowMesh | undefined
  private _hasBottomShadow = true
  private bottomShadow: ShadowMesh | undefined

  private addShadow(shadow: ShadowMesh) {
    this.add(shadow)
    this.shadows.push(shadow)
  }

  private removeShadow(shadow: ShadowMesh) {
    this.remove(shadow)
    _.remove(this.shadows, shadow)
  }

  private addTopShadow() {
    if (this.options.innerTop) {
      const shadow = new InnerTopShadow(this.options.innerTop)
      this.topShadow = shadow
      this.addShadow(shadow)
    }
  }

  private removeTopShadow() {
    if (this.topShadow) {
      _.remove(this.shadows, this.topShadow)
      this.removeShadow(this.topShadow)
      this.topShadow = undefined
    }
  }

  private addBottomShadow() {
    if (this.options.innerBottom) {
      const shadow = new InnerBottomShadow(this.options.innerBottom)
      this.bottomShadow = shadow
      this.addShadow(shadow)
    }
  }

  private removeBottomShadow() {
    if (this.bottomShadow) {
      _.remove(this.shadows, this.bottomShadow)
      this.removeShadow(this.bottomShadow)
      this.bottomShadow = undefined
    }
  }

  constructor(private options: InnerShadowOptions = defaultInnerShadowOptions) {
    super()

    if (options.innerLeft) {
      this.addShadow(new InnerLeftShadow(options.innerLeft))
    }
    if (options.innerRight) {
      this.addShadow(new InnerRightShadow(options.innerRight))
    }
    this.addTopShadow()
    this.addBottomShadow()
    if (options.innerRear) {
      this.addShadow(new InnerRearShadow(options.innerRear))
    }
  }

  public setSize(size: THREE.Vector3) {
    this.shadows.forEach((shadow) => shadow.setSize(size))
  }

  public get hasTopShadow() {
    return this._hasTopShadow
  }

  public set hasTopShadow(value: boolean) {
    this._hasTopShadow = value
    if (value) {
      this.addTopShadow()
    } else {
      this.removeTopShadow()
    }
  }

  public get hasBottomShadow() {
    return this._hasBottomShadow
  }

  public set hasBottomShadow(value: boolean) {
    this._hasBottomShadow = value
    if (value) {
      this.addBottomShadow()
    } else {
      this.removeBottomShadow()
    }
  }
}
