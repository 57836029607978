import { Drawer, DrawerOptions, InteractionBehavior, ItemContext } from '@teamsesam/configurator-core'
import { ItemProperties } from '../config'
import * as THREE from 'three'
import {
  columnWidths,
  frontPlateThickness,
  getRollingDrawerDepth,
  getRollingDrawerWidth,
  rollingDrawerHeight,
  rollingDrawerNotchHeight,
  rollingDrawerNotchWidth,
} from '../constants'

const createFrontGeometry = (width: number) => {
  const shape = new THREE.Shape()
    .moveTo(0, 0)
    .lineTo(0, rollingDrawerHeight)
    .lineTo((width - rollingDrawerNotchWidth) / 2, rollingDrawerHeight)
    .lineTo((width - rollingDrawerNotchWidth) / 2, rollingDrawerHeight - rollingDrawerNotchHeight)
    .lineTo((width + rollingDrawerNotchWidth) / 2, rollingDrawerHeight - rollingDrawerNotchHeight)
    .lineTo((width + rollingDrawerNotchWidth) / 2, rollingDrawerHeight)
    .lineTo(width, rollingDrawerHeight)
    .lineTo(width, 0)
    .lineTo(0, 0)
  const geomtry = new THREE.ExtrudeGeometry(shape, { depth: frontPlateThickness, bevelSize: 0.1, bevelThickness: 0.1 })
  geomtry.center()
  return geomtry
}

export const rollingDrawer = (context: ItemContext) => {
  const { item, on, materials, setInteractionBehavior } = context

  let drawerFront: THREE.Mesh
  let drawer: Drawer

  const getDrawerHeightOptions = () => {
    return {
      boxHeight: rollingDrawerHeight,
      height: rollingDrawerHeight,
    }
  }

  const getDrawerWidthAndDepthOptions = () => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth) as number
    const drawerDepth = getRollingDrawerDepth(item.getPropertyValue(ItemProperties.ShelfDepth))
    const drawerWidth = getRollingDrawerWidth(columnWidth)

    return {
      boxWidth: drawerWidth,
      boxDepth: 0,
      width: drawerWidth,
      depth: drawerDepth,
    }
  }

  const createDrawer = () => {
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth)

    const drawerOptions: DrawerOptions = {
      ...getDrawerHeightOptions(),
      ...getDrawerWidthAndDepthOptions(),
      plateThickness: frontPlateThickness,
      boxMaterial: materials.interiorMaterial,
    }
    drawerFront = new THREE.Mesh(createFrontGeometry(drawerOptions.width), materials.shelfMaterial)
    drawer = new Drawer(drawerFront, drawerOptions)
    drawer.position.z = (shelfDepth - drawerOptions.depth) / 2
    item.add(drawer)

    item.updateBoundingBox()
  }

  const updateDrawerWidthAndDepth = () => {
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth)

    const drawerOptions = getDrawerWidthAndDepthOptions()
    drawer.update(drawerOptions)
    drawer.position.z = (shelfDepth - drawerOptions.depth) / 2
    drawerFront.geometry = createFrontGeometry(drawerOptions.width)
    item.updateBoundingBox()
  }

  createDrawer()

  on.propertyChanged([ItemProperties.ColumnWidth, ItemProperties.ShelfDepth], updateDrawerWidthAndDepth)

  on.materialsChanged((materials) => (drawerFront.material = materials.shelfMaterial))

  on.selected(() => {
    drawer.animateOut()
    return () => drawer.animateIn()
  })

  on.validateDropBox((dropBox) => {
    if (dropBox.size.z === 22.5) {
      return 'Rollschubladen nicht für Tiefe 22.5cm'
    }
    if (!columnWidths.includes(dropBox.size.x)) {
      return 'Rollschubladen nur für Standardmasse'
    }
    return undefined
  })

  setInteractionBehavior(InteractionBehavior.SelectableAndDraggable)
}
