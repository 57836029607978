import { Typography } from '@mui/material'
import { Item, useItemProperties } from '@teamsesam/configurator-core'

export interface InnerHeightProps {
  item: Item
  getHeight: () => number
}

export const InnerHeight = (props: InnerHeightProps) => {
  const { item, getHeight } = props
  useItemProperties(item)

  const height = getHeight()

  if (height < 1) {
    return null
  }

  return <Typography style={{ fontSize: '12px' }}>{height} cm</Typography>
}
