import React from 'react'
import { DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { CoreDialog } from '@teamsesam/core'
import { usePartsListAndPrices } from '../hooks/usePartsListAndPrices'
import { useFormatPrice } from '../hooks/useFormatPrice'

interface PartsListDialogProps {
  onClose: () => void
}

export const PartsListDialog = (props: PartsListDialogProps) => {
  const { onClose } = props
  const { positions, totalPrice } = usePartsListAndPrices()
  const formatPrice = useFormatPrice()

  return (
    <CoreDialog
      showCloseButton
      open={true}
      onClose={onClose}
      sx={{
        '& .MuiDialog-container': {
          position: 'absolute',
          right: 0,
          bottom: 0,
          alignItems: 'flex-end',
        },
        '& .MuiDialog-paper': {
          margin: 0,
          borderRadius: 0,
        },
      }}
    >
      <DialogTitle>Stückliste</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Artikel-Nr.</TableCell>
              <TableCell>Bezeichnung</TableCell>
              <TableCell>Masse</TableCell>
              <TableCell align="right">Anz.</TableCell>
              <TableCell align="right">Preis</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {positions.map((position) => (
              <TableRow key={`${position.articleNr}_${position.dimension}`}>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{position.articleNr}</TableCell>
                <TableCell>{position.name}</TableCell>
                <TableCell>{position.dimension}</TableCell>
                <TableCell align="right">{position.quantity}</TableCell>
                <TableCell align="right">{formatPrice(position.price)}</TableCell>
                <TableCell align="right">{formatPrice(position.totalPrice)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="right" colSpan={5}>
                <b>Total</b>
              </TableCell>
              <TableCell align="right">
                <b>{formatPrice(totalPrice)}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </CoreDialog>
  )
}
