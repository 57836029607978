import {
  usePublishProduct,
  useSendMail,
  useSerializeItemsAndPropertyDefaultValues,
  useScreenshot,
  ScreenshotType,
  getCompositonUrl,
  useRootItem,
} from '@teamsesam/configurator-core'
import { useCallback } from 'react'
import { useSetView } from './useSetView'
import { PartsListPosition, usePartsListAndPrices } from './usePartsListAndPrices'
import { getShelfHeight, getShelfWidth, mailPartsList } from './useCreateOrder'
import { ItemProperties } from '../three/config'
import { useFormatPrice } from './useFormatPrice'

const mailBody = (
  loadDesignUrl: string,
  partsList: PartsListPosition[],
  totalPrice: number,
  formatPrice: (amount: number) => string,
  shelfWidth: number,
  shelfHeight: number,
  shelfDepth: number,
) => `
<html>
<head>
</head>
<body>
<div style="width:600px; height: 400px; display: flex;">
  <img width="600" height="400" src="cid:screenshot3D" alt="UPW-Regal" />
</div>

<p>
Dimension: ${shelfWidth} x ${shelfHeight} x ${shelfDepth} cm (BxHxT)
</p>

<p>
  Es wurde unter folgendem Link gespeichert und kann jederzeit weiterbearbeitet werden.<br/>
  <a href="${loadDesignUrl}">${loadDesignUrl}</a>
</p>

${mailPartsList(partsList, totalPrice, formatPrice)}

<p>Mit freundlichen Grüssen<br />
Ihr wb form Team</p>

<p>wb form ag<br />
Uraniastrasse 40 | CH-8001 Zürich<br />
<a href="tel:+41442159500">+41 44 215 95 00</a> | <a href="mailto:info@wbform.ch">info@wbform.ch</a></p>
<a href="https://www.wbform.com/">www.wbform.com</a>
</body>
</html>
`

export const useSaveDesign = () => {
  const { set3DView } = useSetView()
  const saveProduct = usePublishProduct()
  const sendMail = useSendMail()
  const serialize = useSerializeItemsAndPropertyDefaultValues()
  const takeScreenshot = useScreenshot()
  const { totalPrice, positions } = usePartsListAndPrices()
  const formatPrice = useFormatPrice()
  const shelfItem = useRootItem()

  return useCallback(
    async (email: string) => {
      set3DView()
      const productScreenshot = takeScreenshot(ScreenshotType.ByteString, { resolution: { x: 1920, y: 1080 } })
      const emailScreenshot = takeScreenshot(ScreenshotType.Default, { resolution: { x: 1920, y: 1080 } })
      const shelfDepth = shelfItem.getPropertyValue(ItemProperties.ShelfDepth)
      const shelfWidth = getShelfWidth(shelfItem)
      const shelfHeight = getShelfHeight(shelfItem)

      const content = serialize()
      const product = await saveProduct({
        title: 'Kundenregal',
        net: totalPrice,
        state: 'draft',
        images: [{ attachment: productScreenshot }],
        reference: email,
        compositions: [{ content }],
      })

      await sendMail({
        to: email,
        subject: 'Ihr UPW-Regal',
        html: mailBody(
          getCompositonUrl(product.compositions[0]),
          positions,
          totalPrice,
          formatPrice,
          shelfWidth,
          shelfHeight,
          shelfDepth,
        ),
        attachments: [
          { cid: 'screenshot3D', path: emailScreenshot },
          { filename: `UPW-3d.png`, path: emailScreenshot },
        ],
      })
    },
    [formatPrice, positions, saveProduct, sendMail, serialize, set3DView, shelfItem, takeScreenshot, totalPrice],
  )
}
