import React, { useEffect } from 'react'
import {
  LayoutRegion,
  BorderLayoutApplication,
  useSetCameraView,
  useAddItem,
  TextMessage,
  PropertyValidationMessage,
  ItemOverInvalidDropBoxMessage,
  ItemRemovedFromInvalidDropBoxMessage,
  BackendErrorMessage,
  useDeserializeItemsAndPropertyDefaultValues,
  useIsStarted,
  useLoadComposition,
  useLoadingScreen,
} from '@teamsesam/configurator-core'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { theme } from '../theme'
import { Properties } from './Properties'
import { ItemType } from '../three/config'
import { Color } from './Color'
import { Price } from './Price'
import { Buttons } from './Buttons'
import { SnackbarMessages } from './SnackbarMessages'
import { SwitchCameraButton } from './SwitchCameraButton'
import { LocalizationProvider, MessageBoxProvider } from '@teamsesam/core'
import { LoadingScreen } from './LoadingScreen'
import { MailTestModeIndicator } from './MailTestModeIndicator'

export const Main = () => {
  const setCameraView = useSetCameraView()
  const addItem = useAddItem()
  const started = useIsStarted()
  const loadComposition = useLoadComposition()
  const restore = useDeserializeItemsAndPropertyDefaultValues()
  const { showLoadingScreen, hideLoadingScreen } = useLoadingScreen()

  useEffect(() => {
    showLoadingScreen('Loading...')
  }, [showLoadingScreen])

  useEffect(() => {
    if (started) {
      loadComposition({ customRestore: (composition) => restore(composition.content) }).then((compositionLoaded) => {
        if (!compositionLoaded) {
          addItem(ItemType.Shelf)
        }
        setCameraView('default')
        hideLoadingScreen()
      })
    }
  }, [addItem, hideLoadingScreen, loadComposition, restore, setCameraView, started])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider locale="de-CH" currency="CHF">
        <MessageBoxProvider>
          <LoadingScreen />
          <MailTestModeIndicator envVariable={process.env.REACT_APP_MAIL_TEST_MODE} />
          <BorderLayoutApplication enableDevTools enableOverlay rightDrawerWidth={450}>
            <Properties region={LayoutRegion.RightDrawer} />
            <Color region={LayoutRegion.Canvas} />
            <SwitchCameraButton region={LayoutRegion.Canvas} />
            <Price region={LayoutRegion.Canvas} />
            <Buttons region={LayoutRegion.Canvas} />
            <SnackbarMessages
              types={[
                TextMessage,
                PropertyValidationMessage,
                ItemOverInvalidDropBoxMessage,
                ItemRemovedFromInvalidDropBoxMessage,
                BackendErrorMessage,
              ]}
            />
          </BorderLayoutApplication>
        </MessageBoxProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
