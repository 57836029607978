import { BevelEdge, createBoardGeometry, ItemContext, RotationAnimation, RotationHinge } from '@teamsesam/configurator-core'
import * as THREE from 'three'
import { DoorHinge, ItemProperties } from '../config'
import { frontOffsetY, frontPlateThickness, getHandleMesh, handleOffset } from '../constants'

export const door = (context: ItemContext) => {
  const { item, on, materials } = context
  let currentMaterials = materials

  let door: THREE.Mesh | undefined = undefined
  const handle = getHandleMesh('vertical')
  const doorWithHandle = new THREE.Object3D()
  doorWithHandle.add(handle)
  item.add(doorWithHandle)

  const animation = new RotationAnimation(doorWithHandle, {
    hinge: RotationHinge.Left,
    max: Math.PI / 2,
    min: Math.PI / 4,
  })
  item.itemAnimations.addAnimation('open', animation)

  const updateHinge = () => {
    const doorHinge = item.getPropertyValue(ItemProperties.DoorHinge) as DoorHinge
    const doorWidth = item.getPropertyValue(ItemProperties.DoorWidth) as number

    const rightX = doorWidth / 2 - handleOffset
    handle.position.x = doorHinge === DoorHinge.Left ? rightX : -rightX
    animation.updateOptions({ hinge: doorHinge === DoorHinge.Left ? RotationHinge.Left : RotationHinge.Right })

    if (item.isSelected) {
      animation.start()
    }
  }

  const updateDoor = () => {
    if (door) {
      doorWithHandle.remove(door)
    }

    const doorHeight = item.getPropertyValue(ItemProperties.DoorHeight) + frontOffsetY
    const doorWidth = item.getPropertyValue(ItemProperties.DoorWidth)

    const geometry = createBoardGeometry(new THREE.Vector3(doorWidth, doorHeight, frontPlateThickness), {
      bevelEdge: BevelEdge.all,
      bevelWidth: 0.1,
    })
    door = new THREE.Mesh(geometry, currentMaterials.shelfMaterial)
    doorWithHandle.add(door)

    updateHinge()

    item.updateBoundingBox()
  }

  updateDoor()

  on.propertyChanged([ItemProperties.DoorHeight, ItemProperties.DoorWidth], updateDoor)
  on.propertyChanged([ItemProperties.DoorHinge], updateHinge)

  on.materialsChanged((materials) => {
    currentMaterials = materials
    if (door) {
      door.material = materials.shelfMaterial
    }
  })
}
