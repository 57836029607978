import { customBoundingBoxBySize, itemCreator, OverlayAnchorX, OverlayAnchorY, OverlayAnchorZ } from '@teamsesam/configurator-core'
import * as THREE from 'three'
import { InnerHeight } from '../../components/InnerHeight'
import { ItemProperties } from '../config'
import { Views } from '../../hooks/useSetView'

export const innerMeasurement: itemCreator = (context) => {
  const { item, on } = context

  item.customBoundingBox = customBoundingBoxBySize(
    () => item.getPropertyValue(ItemProperties.ColumnWidth),
    () => item.getPropertyValue(ItemProperties.InnerMeasurementHeight),
    () => item.getPropertyValue(ItemProperties.ShelfDepth),
  )

  on.propertyChanged([ItemProperties.ShelfDepth, ItemProperties.ColumnWidth, ItemProperties.InnerMeasurementHeight], () =>
    item.updateBoundingBox(),
  )

  item.overlays.addOverlay({
    component: InnerHeight,
    props: { item, getHeight: () => item.getPropertyValue(ItemProperties.InnerMeasurementHeight) },
    anchors: { x: OverlayAnchorX.centerLeft, y: OverlayAnchorY.center, z: OverlayAnchorZ.front },
    offset3D: new THREE.Vector3(3, 0, 0),
    visibleForViews: [Views['2D']],
  })
}
