import { behavior, CameraObserver, Configurator, orthographicCamera, OrthographicCameraView } from '@teamsesam/configurator-core'
import * as THREE from 'three'

export const addCameras = (): behavior => {
  return ({ getInstance }) => {
    const cameraObserver = getInstance(CameraObserver)
    const configurator = getInstance(Configurator)

    cameraObserver
      .addCamera((container) => {
        const camera = new THREE.PerspectiveCamera(30, container.clientWidth / container.clientHeight, 10, 10000)
        camera.name = '3D'
        camera.position.set(0, 0, 100)
        return camera
      }, '3D')
      .withControls((controls) => {
        controls.minPolarAngle = Math.PI / 4
        controls.maxPolarAngle = Math.PI / 2

        controls.barrierMinPolarAngle = Math.PI / 2.8

        controls.minAzimuthAngle = -Math.PI / 3
        controls.maxAzimuthAngle = Math.PI / 3

        controls.barrierMinAzimuthAngle = -Math.PI / 6
        controls.barrierMaxAzimuthAngle = Math.PI / 6

        controls.enableKeys = false
      })
      .addView('default', {
        getObject: () => configurator.state.items[0],
        margin: '15%',
      })

    cameraObserver
      .addCamera(() => {
        const camera = orthographicCamera()
        camera.name = '2D'
        camera.far = 1000
        camera.position.z = 500
        return camera
      }, '2D')
      .addView(
        'default',
        (container, controls, camera) =>
          new OrthographicCameraView(camera, container, {
            getObject: () => configurator.state.items[0],
            margin: '15%',
          }),
      )
  }
}
