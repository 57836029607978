import { ItemContext } from '@teamsesam/configurator-core'
import { ItemProperties } from '../config'
import * as THREE from 'three'
import { getTraverseWidth, plateThickness, traverseHeight } from '../constants'
import { defaultInnerShadowOptions, InnerShadow } from '../shadows/inner/innerShadow'

export const traverse = (context: ItemContext) => {
  const { item, on, materials } = context

  const traverse = new THREE.Mesh(new THREE.BoxGeometry(1, traverseHeight, plateThickness), materials.shelfMaterial)
  item.add(traverse)

  const { innerRear } = defaultInnerShadowOptions
  const shadow = new InnerShadow({ innerRear: { ...innerRear, intensity: 0.3 } })
  shadow.hasBottomShadow = false
  shadow.hasTopShadow = false
  item.add(shadow)

  const updateTraverse = () => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth) as number
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth) as number

    traverse.scale.x = getTraverseWidth(columnWidth)
    traverse.position.z = (shelfDepth - plateThickness) / -2

    shadow.setSize(new THREE.Vector3(getTraverseWidth(columnWidth), traverseHeight, 0.1))
    shadow.position.z = shelfDepth / -2 + plateThickness + 0.1

    item.updateBoundingBox()
  }

  updateTraverse()

  on.propertyChanged([ItemProperties.ColumnWidth, ItemProperties.ShelfDepth], updateTraverse)

  on.materialsChanged((materials) => (traverse.material = materials.shelfMaterial))
}
