import { Typography } from '@mui/material'
import { useItemProperties } from '@teamsesam/configurator-core'
import React from 'react'
import { ItemProperties } from '../../three/config'
import { flapHeight, getFlapWidth } from '../../three/constants'
import { LabelContainer } from '../LabelContainer'
import { ItemOptionsProps } from './InteriorProperties'

export function FlapOptions(props: ItemOptionsProps) {
  const { item } = props
  const { columnWidth } = useItemProperties(item, [ItemProperties.ColumnWidth])
  return (
    <LabelContainer label="Masse">
      <Typography>
        {flapHeight} x {getFlapWidth(columnWidth)} cm
      </Typography>
    </LabelContainer>
  )
}
