import { columnWidths, maxColumnWidth, minColumnWidth, plateThickness } from './constants'

export const columnWidthsMaxToMin = [...columnWidths].reverse()
const minStandardColumnWidth = Math.min(...columnWidths)

export const calculateStandardLengthsColumnWidthsRecursive = (restWidth: number): number[] | undefined => {
  if (columnWidthsMaxToMin.includes(restWidth)) {
    return [restWidth]
  }
  if (restWidth < minStandardColumnWidth) {
    return undefined
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const width of columnWidthsMaxToMin) {
    const result = calculateStandardLengthsColumnWidthsRecursive(restWidth - width)
    if (result) {
      return [width, ...result]
    }
  }
  return undefined
}

export const calculateNonStandardLengthsColumnWidthsRecursive = (restWidth: number): number[] => {
  if (restWidth < maxColumnWidth) {
    return [restWidth]
  }

  for (const width of columnWidthsMaxToMin) {
    if (restWidth >= width + minColumnWidth) {
      const result = calculateNonStandardLengthsColumnWidthsRecursive(restWidth - width)
      return [width, ...result]
    }
  }

  throw new Error('no valid column widths found')
}

export const calculateColumnWidths = (shelfWidth: number): number[] => {
  const columnWidthTotal = shelfWidth - plateThickness
  return (
    calculateStandardLengthsColumnWidthsRecursive(columnWidthTotal) || calculateNonStandardLengthsColumnWidthsRecursive(columnWidthTotal)
  )
}
