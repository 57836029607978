import * as THREE from 'three'
import { shadowOffset, ShadowTexureForUvUpdateOptions, ShadowWithUvUpdateMesh } from '../shadowMesh'

export class InnerTopShadow extends ShadowWithUvUpdateMesh {
  constructor(options: ShadowTexureForUvUpdateOptions) {
    super('right', 'top', options)
    this.rotateX(Math.PI / 2)
  }

  protected getShadowSize(itemSize: THREE.Vector3): THREE.Vector2 {
    return new THREE.Vector2(itemSize.x, itemSize.z)
  }

  protected setPosition(size: THREE.Vector3, shadowSize: THREE.Vector2): void {
    this.position.y = size.y / 2 - shadowOffset
  }
}
