import * as THREE from 'three'

export const plateThickness = 2
export const columnWidths = [43, 52, 61, 88].map((value) => value + plateThickness)
export const maxColumnWidth = Math.max(...columnWidths)
export const sideHeights = [54, 72, 99, 117, 162, 207, 234, 261]
export const maxShelfHeight = sideHeights[sideHeights.length - 1]
export const shelfDepths = [22.5, 36.0, 49.5]
export const minShelfDepth = shelfDepths[0]
export const maxShelfDepth = shelfDepths[shelfDepths.length - 1]
export const maxTrayCount = 9
export const maxShelfWidth = 452
export const holeRadius = 0.25
export const shelfBackWallThickness = 0.3
export const frontPlateThickness = 1
export const frontClearance = 1
export const handleOffset = 4
export const handleThickness = 1
export const handleLength = 4
export const handleDepth = 2
export const flapHeight = 44
export const drawerHeightFlat = 8
export const drawerHeightHigh = 17
export const rollingDrawerHeight = 27
export const rollingDrawerNotchWidth = 10
export const rollingDrawerNotchHeight = 3
export const interiorBackWallThickness = 0.7
export const traverseHeight = 8
export const minShelfHeight = 45
export const minColumnWidth = 45
export const minShelfWidth = minColumnWidth + plateThickness
export const frontOffsetY = 0.5
export const plinthPanelHeight = 5 - plateThickness / 2
export const plinthPanelOffsetZ = 2

export const calculateShelfWidths = () => {
  const widths = [47, 56, 65]
  for (let i = 92; i <= maxShelfWidth; i += 9) {
    widths.push(i)
  }
  return widths
}

export const isStandardColumnWidth = (columnWidth: number) => {
  return columnWidths.includes(columnWidth)
}

export const getNextStandardColumnWidth = (columnWidth: number) => {
  for (const standardColumnWidth of columnWidths) {
    if (standardColumnWidth > columnWidth) {
      return standardColumnWidth
    }
  }
  throw new Error(`no next standard column width found for ${columnWidth}`)
}

export const isStandardSideWallHeight = (sideWallHeight: number) => {
  return sideHeights.includes(sideWallHeight)
}

export const getNextStandardSideWallHeight = (sideWallHeight: number) => {
  for (const standardSideWallHeight of sideHeights) {
    if (standardSideWallHeight > sideWallHeight) {
      return standardSideWallHeight
    }
  }
  throw new Error(`no next standard side wall height found for ${sideWallHeight}`)
}

export const isDoubleDoor = (columnWidth: number): boolean => {
  return columnWidth > 80
}

export const getDoorWidth = (columnWidth: number): number => {
  return columnWidth - frontClearance
}

export const getFlapWidth = (columnWidth: number): number => {
  return columnWidth - frontClearance
}

export const getTrayWidth = (columnWidth: number): number => {
  return columnWidth - plateThickness
}

export const getTrayDepth = (shelfDepth: number): number => {
  return shelfDepth - 2
}

export const getFixTrayDepth = (shelfDepth: number): number => {
  return shelfDepth
}

export const getDrawerWidth = (columnWidth: number): number => {
  return columnWidth - frontClearance
}

export const getDrawerDepth = (shelfDepth: number): number => {
  return shelfDepth > 37 ? 47 : 34
}

export const getRollingDrawerWidth = (columnWidth: number): number => {
  return columnWidth - 3
}

export const getRollingDrawerDepth = (shelfDepth: number): number => {
  return shelfDepth > 37 ? 43 : 30
}

export const getInteriorBackWallWidth = (columnWidth: number): number => {
  return columnWidth
}

export const getTraverseWidth = (columnWidth: number): number => {
  return columnWidth - plateThickness
}

export const getPlinthPanelWidth = (columnWidth: number): number => {
  return columnWidth - plateThickness
}

export const getHandleMesh = (direction: 'vertical' | 'horizontal'): THREE.Mesh => {
  const material = new THREE.MeshPhysicalMaterial({ color: 0xcccccc, metalness: 0.6, roughness: 0.3, emissive: 0.5 })
  const geometry = new THREE.TorusGeometry(handleLength / 2, handleThickness / 2, 7, 14, Math.PI)
  geometry.center()
  const handle = new THREE.Mesh(geometry, material)
  handle.rotateX(Math.PI / 2)
  if (direction === 'vertical') {
    handle.rotateY(Math.PI / 2)
  }
  handle.position.z = (handleDepth + frontPlateThickness) / 2
  return handle
}
