import React from 'react'
import { Typography } from '@mui/material'
import { Horizontal, HorizontalProps } from '@teamsesam/core'

interface LabelContainerProps extends HorizontalProps {
  label: string
  radios?: boolean
}

export function LabelContainer(props: LabelContainerProps) {
  const { label, children, radios, ...rest } = props
  return (
    <Horizontal
      sx={{
        alignItems: radios ? 'center' : 'baseline',
        marginBottom: radios ? '-8px !important' : undefined,
        '.MuiInput-root': { marginTop: '0 !important' },
      }}
      {...rest}
    >
      <Typography style={{ minWidth: '95px' }}>{label}</Typography>
      {children}
    </Horizontal>
  )
}
