export interface Article {
  articleNr: string
  price: number
}

export const getArticle = (articleNr: string): Article => {
  const article = articles.find((article) => article.articleNr === articleNr)
  if (!article) {
    throw new Error(`no article found for article-nr ${articleNr}`)
  }
  return article
}

const articles: Article[] = [
  // Seiten beidseitig gelocht
  { articleNr: '600009', price: 130.0 },
  { articleNr: '602309', price: 147.0 },
  { articleNr: '602310', price: 175.0 },
  { articleNr: '602311', price: 193.0 },
  { articleNr: '602312', price: 232.0 },
  { articleNr: '602313', price: 275.0 },
  { articleNr: '602314', price: 302.0 },
  { articleNr: '602315', price: 329.0 },
  { articleNr: '602316', price: 155.0 },
  { articleNr: '602318', price: 182.0 },
  { articleNr: '602319', price: 221.0 },
  { articleNr: '602321', price: 250.0 },
  { articleNr: '602323', price: 312.0 },
  { articleNr: '602324', price: 378.0 },
  { articleNr: '602326', price: 418.0 },
  { articleNr: '602328', price: 458.0 },
  { articleNr: '602330', price: 180.0 },
  { articleNr: '602331', price: 218.0 },
  { articleNr: '604203', price: 270.0 },
  { articleNr: '604204', price: 305.0 },
  { articleNr: '602332', price: 392.0 },
  { articleNr: '602333', price: 480.0 },
  { articleNr: '602334', price: 532.0 },
  { articleNr: '602335', price: 585.0 },
  { articleNr: '263964', price: 66.0 },

  // Seiten einseitig gelocht
  { articleNr: '602474', price: 130.0 },
  { articleNr: '602475', price: 147.0 },
  { articleNr: '602476', price: 175.0 },
  { articleNr: '602477', price: 193.0 },
  { articleNr: '602478', price: 232.0 },
  { articleNr: '602479', price: 275.0 },
  { articleNr: '602480', price: 302.0 },
  { articleNr: '602481', price: 329.0 },
  { articleNr: '602482', price: 155.0 },
  { articleNr: '602483', price: 182.0 },
  { articleNr: '602484', price: 221.0 },
  { articleNr: '602485', price: 250.0 },
  { articleNr: '602486', price: 312.0 },
  { articleNr: '602487', price: 378.0 },
  { articleNr: '602488', price: 418.0 },
  { articleNr: '602489', price: 458.0 },
  { articleNr: '602490', price: 180.0 },
  { articleNr: '602491', price: 218.0 },
  { articleNr: '602492', price: 270.0 },
  { articleNr: '602493', price: 305.0 },
  { articleNr: '602495', price: 392.0 },
  { articleNr: '602496', price: 480.0 },
  { articleNr: '602497', price: 532.0 },
  { articleNr: '602498', price: 585.0 },
  { articleNr: '263967', price: 66.0 },

  // Verstellbare Tablare
  { articleNr: '602348', price: 82.0 },
  { articleNr: '602349', price: 90.0 },
  { articleNr: '602350', price: 98.0 },
  { articleNr: '602351', price: 120.0 },
  { articleNr: '602352', price: 104.0 },
  { articleNr: '602353', price: 115.0 },
  { articleNr: '602354', price: 127.0 },
  { articleNr: '602355', price: 162.0 },
  { articleNr: '602356', price: 126.0 },
  { articleNr: '602357', price: 142.0 },
  { articleNr: '602358', price: 156.0 },
  { articleNr: '602359', price: 203.0 },
  { articleNr: '263967_2', price: 33.0 }, // error from UPW: same article-nr. twice

  // Böden (feste Tablare)
  { articleNr: '602336', price: 92.0 },
  { articleNr: '602337', price: 102.0 },
  { articleNr: '602338', price: 108.0 },
  { articleNr: '602339', price: 133.0 },
  { articleNr: '602340', price: 115.0 },
  { articleNr: '602341', price: 126.0 },
  { articleNr: '602342', price: 138.0 },
  { articleNr: '602343', price: 175.0 },
  { articleNr: '602344', price: 135.0 },
  { articleNr: '602345', price: 152.0 },
  { articleNr: '602346', price: 168.0 },
  { articleNr: '602347', price: 215.0 },
  { articleNr: '263965', price: 33.0 },

  // Traversen
  { articleNr: '602360', price: 80.0 },
  { articleNr: '602361', price: 85.0 },
  { articleNr: '602362', price: 90.0 },
  { articleNr: '602363', price: 105.0 },
  { articleNr: '263968', price: 33.0 },

  // !!!!!!! ARTICLES NO LONGER SOLD !!!!!!!

  // Sockel ohne Falz
  { articleNr: '602372', price: 72.6 },
  { articleNr: '602373', price: 75.9 },
  { articleNr: '602370', price: 78.1 },
  { articleNr: '602371', price: 88.0 },
  { articleNr: '602371_2', price: 33.0 }, // no article-nr. from UPW

  // Schubladen flach
  { articleNr: '265089', price: 358.6 },
  { articleNr: '264742', price: 372.9 },
  { articleNr: '265109', price: 380.6 },
  { articleNr: '265113', price: 425.7 },
  { articleNr: '265116', price: 436.7 },
  { articleNr: '265104', price: 448.8 },

  // Schubladen hoch
  { articleNr: '265097', price: 414.7 },
  { articleNr: '264743', price: 434.5 },
  { articleNr: '265101', price: 453.2 },
  { articleNr: '265121', price: 490.6 },
  { articleNr: '265124', price: 510.4 },
  { articleNr: '265090', price: 529.1 },

  // Rollschubladen
  { articleNr: '602402', price: 435.6 },
  { articleNr: '602404', price: 463.1 },
  { articleNr: '602406', price: 491.7 },
  { articleNr: '602407', price: 566.5 },
  { articleNr: '602401', price: 482.9 },
  { articleNr: '602400', price: 514.8 },
  { articleNr: '602398', price: 545.6 },
  { articleNr: '602397', price: 632.5 },

  // Türen
  { articleNr: '622779', price: 339.9 },
  { articleNr: '622780', price: 360.8 },
  { articleNr: '622782', price: 381.7 },
  { articleNr: '622783', price: 360.8 },
  { articleNr: '622784', price: 385.0 },
  { articleNr: '622785', price: 410.3 },
  { articleNr: '622786', price: 381.7 },
  { articleNr: '622787', price: 410.3 },
  { articleNr: '622788', price: 437.8 },
  { articleNr: '622789', price: 446.6 },
  { articleNr: '622790', price: 484.0 },
  { articleNr: '622791', price: 521.4 },

  // Doppeltüren
  { articleNr: '622804', price: 750.2 },
  { articleNr: '622868', price: 792.0 },
  { articleNr: '622869', price: 834.9 },
  { articleNr: '622870', price: 962.5 },

  // Klappen
  { articleNr: '622805', price: 364.1 },
  { articleNr: '622806', price: 386.1 },
  { articleNr: '622807', price: 436.7 },
  { articleNr: '622809', price: 491.7 },

  // Rückwände 7mm
  { articleNr: '613653', price: 171.6 },
  { articleNr: '613654', price: 187.0 },
  { articleNr: '613655', price: 202.4 },
  { articleNr: '613656', price: 247.5 },
  { articleNr: '613657', price: 204.6 },
  { articleNr: '613658', price: 222.2 },
  { articleNr: '613659', price: 275.0 },
  { articleNr: '613660', price: 243.1 },
  { articleNr: '613661', price: 305.8 },
  { articleNr: '613662', price: 390.5 },

  // Rückwände Hartfaser 3mm
  { articleNr: '602534', price: 61.6 },
]
