import { InteractionBehavior, ItemContext } from '@teamsesam/configurator-core'
import { ItemProperties } from '../config'
import * as THREE from 'three'
import { columnWidths, getInteriorBackWallWidth, interiorBackWallThickness } from '../constants'
import { updateBackWalls, updateDropBoxByDraggableItemResize } from './columnDropBox'

export const interiorBackWall = (context: ItemContext) => {
  const { item, on, materials, setInteractionBehavior, createItem } = context

  const wall = new THREE.Mesh(new THREE.BoxGeometry(1, 1, interiorBackWallThickness), materials.shelfMaterial)
  item.add(wall)

  const updateWall = () => {
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth)
    const wallWidth = getInteriorBackWallWidth(item.getPropertyValue(ItemProperties.ColumnWidth))
    const wallHeight = item.getPropertyValue(ItemProperties.InteriorBackWallHeight)

    wall.scale.x = wallWidth
    wall.scale.y = wallHeight
    wall.position.z = (shelfDepth + interiorBackWallThickness) / -2

    item.updateBoundingBox()
    if (item.parentItem) {
      updateBackWalls(item.parentItem, createItem)
    }
    updateDropBoxByDraggableItemResize(item)
  }

  updateWall()

  on.propertyChanged([ItemProperties.ColumnWidth, ItemProperties.ShelfDepth, ItemProperties.InteriorBackWallHeight], updateWall)

  on.materialsChanged((materials) => (wall.material = materials.shelfMaterial))

  on.validateDropBox((dropBox) => {
    if (!columnWidths.includes(dropBox.size.x)) {
      return 'Rückwande nur für Standardmasse'
    }
    return undefined
  })

  setInteractionBehavior(InteractionBehavior.SelectableAndDraggable)
}
