import React from 'react'
import { Typography } from '@mui/material'
import { LabelRadioGroup, LabelSelect } from '@teamsesam/core'
import { useItemProperties } from '@teamsesam/configurator-core'
import { DoorHinge, ItemProperties } from '../../three/config'
import { LabelContainer } from '../LabelContainer'
import { getDoorWidth, isDoubleDoor } from '../../three/constants'
import { ItemOptionsProps } from './InteriorProperties'

export function DoorOptions(props: ItemOptionsProps) {
  const { item } = props
  const { doorHeight, columnWidth, doorHinge } = useItemProperties(item, [
    ItemProperties.DoorHeight,
    ItemProperties.ColumnWidth,
    ItemProperties.DoorHinge,
  ])
  return (
    <>
      {!isDoubleDoor(columnWidth) && (
        <LabelContainer label="Scharnier" radios>
          <LabelRadioGroup
            row
            value={doorHinge}
            onChange={(e, value) => item.setProperty(ItemProperties.DoorHinge, value)}
            options={[
              { label: 'Links', value: DoorHinge.Left },
              { label: 'Rechts', value: DoorHinge.Right },
            ]}
          />
        </LabelContainer>
      )}
      <LabelContainer label="Höhe">
        <LabelSelect
          value={doorHeight}
          onChange={(e) => item.setProperty(ItemProperties.DoorHeight, e.target.value)}
          options={[
            { value: 44, label: '44 cm' },
            { value: 53, label: '53 cm' },
            { value: 62, label: '62 cm' },
            { value: 89, label: '89 cm' },
          ]}
        />
      </LabelContainer>
      <LabelContainer label="Breite">
        <Typography>{getDoorWidth(columnWidth)} cm</Typography>
      </LabelContainer>
    </>
  )
}
