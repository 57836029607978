import { Snackbar } from '@mui/material'
import {
  MessageVisualizer,
  Messenger,
  subscribeVisualizersByProps,
  TextMessage,
  useInstance,
  VisualizerContainerProps,
} from '@teamsesam/configurator-core'
import React, { useEffect, useState } from 'react'

export const SnackbarMessages = (props: VisualizerContainerProps) => {
  const messenger = useInstance(Messenger)
  const [message, setMessage] = useState<string>()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const visualizer = new SnackbarVisualizer((message) => {
      setMessage(message)
      setOpen(true)
    })
    subscribeVisualizersByProps(messenger, visualizer, props)
  }, [messenger, props])

  return <Snackbar message={message} open={open} onClose={() => setOpen(false)} autoHideDuration={4000} />
}

class SnackbarVisualizer implements MessageVisualizer {
  constructor(private showSnackbar: (message: string) => void) {}

  show<T extends TextMessage>(message: T) {
    const { text, title } = message as any
    this.showSnackbar(title || text)
    return Promise.resolve()
  }
}
