import { Stack, Typography, LinearProgress } from '@mui/material'
import { useLoadingScreen } from '@teamsesam/configurator-core'

export const LoadingScreen = () => {
  const { isVisible, message } = useLoadingScreen()

  if (!isVisible) {
    return null
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        zIndex: 9999,
        backgroundColor: 'white',
        opacity: 0.7,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" align="center">
          {message}
        </Typography>
        <LinearProgress style={{ minWidth: '200px' }} />
      </Stack>
    </div>
  )
}
