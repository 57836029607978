import * as THREE from 'three'
import { shadowOffset, ShadowTexureForUvUpdateOptions, ShadowWithUvUpdateMesh } from '../shadowMesh'

export class InnerRightShadow extends ShadowWithUvUpdateMesh {
  constructor(options: ShadowTexureForUvUpdateOptions) {
    super('right', 'top', options)
    this.rotateY(Math.PI / -2)
  }

  protected getShadowSize(itemSize: THREE.Vector3): THREE.Vector2 {
    return new THREE.Vector2(itemSize.z, itemSize.y)
  }

  protected setPosition(size: THREE.Vector3, shadowSize: THREE.Vector2): void {
    this.position.x = size.x / 2 - shadowOffset
  }
}
