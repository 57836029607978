import { Typography } from '@mui/material'
import { Horizontal, Vertical } from '@teamsesam/core'
import { LayoutProps, useItemProperties, useRootItem } from '@teamsesam/configurator-core'
import { ItemProperties } from '../three/config'
import { usePartsListAndPrices } from '../hooks/usePartsListAndPrices'
import { useFormatPrice } from '../hooks/useFormatPrice'

export function Price(props: LayoutProps) {
  const { totalPrice } = usePartsListAndPrices()
  const shelfItem = useRootItem()
  const { useStandardLengths } = useItemProperties(shelfItem, [ItemProperties.UseStandardLengths])
  const formatPrice = useFormatPrice()

  return (
    <Vertical style={{ position: 'absolute', bottom: 0, paddingLeft: '32px', paddingBottom: '16px', alignItems: 'baseline' }} spacing={1}>
      <Typography>inkl. MwSt</Typography>
      <Horizontal spacing={1}>
        <Typography style={{ fontSize: '28px', fontWeight: 700 }}>CHF</Typography>
        <Typography style={{ fontSize: '28px', fontWeight: totalPrice > 0 ? 700 : undefined }}>{formatPrice(totalPrice)}</Typography>
      </Horizontal>
      <Typography variant="body2" style={{ visibility: useStandardLengths ? 'hidden' : 'visible' }}>
        * individueller Zuschnitt gegen Aufpreis möglich
      </Typography>
    </Vertical>
  )
}
