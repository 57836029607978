import { Configurator, InteractionCompleted, Item, useInstance } from '@teamsesam/configurator-core'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { getArticlePriceAndDescription } from '../three/getArticlePriceAndDescription'

export interface PartsListPosition {
  articleNr: string
  name: string
  dimension: string
  price: number
  quantity: number
  totalPrice: number
}

export interface AdditionalColorPrice {
  name: string
  displayName: string
  price: number
}

export interface PartsListAndPrices {
  positions: PartsListPosition[]
  totalPrice: number
}

export const addToPartsListWithChildren = (item: Item, partsList: PartsListPosition[]) => {
  const articleWithPrice = getArticlePriceAndDescription(item)
  if (articleWithPrice) {
    const existingPosition = partsList.find(
      (position) => position.articleNr === articleWithPrice.articleNr && position.dimension === articleWithPrice.dimension,
    )
    if (existingPosition) {
      existingPosition.quantity++
      existingPosition.totalPrice += existingPosition.price
    } else {
      partsList.push({
        ...articleWithPrice,
        quantity: 1,
        totalPrice: articleWithPrice.price,
      })
    }
  }

  for (const childItem of item.getItems()) {
    addToPartsListWithChildren(childItem, partsList)
  }
}

export const usePartsListAndPrices = (): PartsListAndPrices => {
  const configurator = useInstance(Configurator)
  const [result, setResult] = useState<PartsListAndPrices>({
    positions: [],
    totalPrice: 0,
  })

  useEffect(() => {
    const updatePartsListAndPrice = () => {
      const shelfItem = configurator.state.items.find((item) => item.isType('Shelf'))
      if (!shelfItem) {
        setResult({ positions: [], totalPrice: 0 })
        return
      }
      const partsList: PartsListPosition[] = []
      addToPartsListWithChildren(shelfItem, partsList)
      const sortedPartsList = _.sortBy(partsList, (position) => `${position.name}_${position.dimension}`)

      const totalPrice = _.sum(partsList.map((position) => position.totalPrice))

      setResult({ positions: sortedPartsList, totalPrice })
    }

    updatePartsListAndPrice()
    return configurator.subscribe(InteractionCompleted, () => updatePartsListAndPrice())
  }, [configurator])

  return result
}
