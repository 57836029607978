import React from 'react'
import { Slider, SliderProps } from '@mui/material'
import { LabelContainer } from './LabelContainer'

export interface PropertySliderProps extends SliderProps {
  label: string
  sliderReplacement?: React.ReactElement
}

export function PropertySlider(props: PropertySliderProps) {
  const { label, value, sliderReplacement, ...rest } = props

  return (
    <LabelContainer label={label}>
      {sliderReplacement || (
        <Slider
          value={value}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => `${value} cm`}
          sx={{ '& .MuiSlider-valueLabel': { backgroundColor: 'transparent', fontSize: '16px', top: 0 } }}
          {...rest}
        />
      )}
    </LabelContainer>
  )
}
