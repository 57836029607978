import * as THREE from 'three'
import WallLeftTexture from '../../../assets/shadows/wallLeft.png'
import WallRightTexture from '../../../assets/shadows/wallRight.png'
import FloorLeftTexture from '../../../assets/shadows/floorLeft.png'
import FloorRightTexture from '../../../assets/shadows/floorRight.png'
import FloorCenterTexture from '../../../assets/shadows/floorCenter.png'
import OuterSideTexture from '../../../assets/shadows/outerSide.png'
import { ShadowTexureOptions, ShadowMesh } from '../shadowMesh'
import { TextureCache } from '../textureCache'
import { FloorCenterShadow } from './floorCenterShadow'
import { FloorLeftShadow } from './floorLeftShadow'
import { FloorRightShadow } from './floorRightShadow'
import { OuterLeftShadow } from './outerLeftShadow'
import { OuterRightShadow } from './outerRightShadow'
import { WallLeftShadow } from './wallLeftShadow'
import { WallRightShadow } from './wallRightShadow'

export interface OuterShadowOptions {
  intensity?: number
  wallLeft?: ShadowTexureOptions
  wallRight?: ShadowTexureOptions
  floorLeft?: ShadowTexureOptions
  floorRight?: ShadowTexureOptions
  floorCenter?: ShadowTexureOptions
  outerLeft?: ShadowTexureOptions
  outerRight?: ShadowTexureOptions
}

export const defaultOuterShadowOptions: OuterShadowOptions = {
  intensity: 0.6,
  wallLeft: {
    texture: WallLeftTexture,
  },
  wallRight: {
    texture: WallRightTexture,
  },
  floorLeft: {
    texture: FloorLeftTexture,
    scale: new THREE.Vector2(0.17, 1.6),
  },
  floorRight: {
    texture: FloorRightTexture,
    scale: new THREE.Vector2(0.25, 1.6),
  },
  floorCenter: {
    texture: FloorCenterTexture,
    scale: new THREE.Vector2(1, 1.6),
    intensity: 0.35,
  },
  outerLeft: {
    texture: OuterSideTexture,
  },
  outerRight: {
    texture: OuterSideTexture,
    rotation: Math.PI,
  },
}

TextureCache.appendToDefault([WallLeftTexture, WallRightTexture, FloorLeftTexture, FloorRightTexture, FloorCenterTexture, OuterSideTexture])

export class OuterShadow extends THREE.Object3D {
  private shadows: ShadowMesh[] = []

  private addShadow(shadow: ShadowMesh) {
    this.add(shadow)
    this.shadows.push(shadow)
  }

  private enrichOptions(options: ShadowTexureOptions): ShadowTexureOptions {
    if (this.options.intensity) {
      return { ...options, intensity: this.options.intensity * (options.intensity || 1) }
    }
    return options
  }

  constructor(private options: OuterShadowOptions = defaultOuterShadowOptions) {
    super()

    if (options.wallLeft) {
      this.addShadow(new WallLeftShadow(this.enrichOptions(options.wallLeft)))
    }
    if (options.wallRight) {
      this.addShadow(new WallRightShadow(this.enrichOptions(options.wallRight)))
    }
    if (options.floorLeft) {
      this.addShadow(new FloorLeftShadow(this.enrichOptions(options.floorLeft)))
    }
    if (options.floorRight) {
      this.addShadow(new FloorRightShadow(this.enrichOptions(options.floorRight)))
    }
    if (options.floorCenter) {
      this.addShadow(new FloorCenterShadow(this.enrichOptions(options.floorCenter)))
    }
    if (options.floorCenter) {
      this.addShadow(new FloorCenterShadow(this.enrichOptions(options.floorCenter)))
    }
    if (options.outerLeft) {
      this.addShadow(new OuterLeftShadow(this.enrichOptions(options.outerLeft)))
    }
    if (options.outerRight) {
      this.addShadow(new OuterRightShadow(this.enrichOptions(options.outerRight)))
    }
  }

  public setSize(size: THREE.Vector3) {
    this.shadows.forEach((shadow) => shadow.setSize(size))
  }
}
