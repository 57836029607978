import React from 'react'
import { Checkbox, Typography } from '@mui/material'
import { useItemProperties } from '@teamsesam/configurator-core'
import { ItemProperties } from '../../three/config'
import { LabelContainer } from '../LabelContainer'
import { getTrayWidth } from '../../three/constants'
import { ItemOptionsProps } from './InteriorProperties'

export function TrayOptions(props: ItemOptionsProps) {
  const { item } = props
  const { columnWidth, isFixTray } = useItemProperties(item, [ItemProperties.ColumnWidth, ItemProperties.IsFixTray])
  return (
    <>
      <LabelContainer label="Breite">
        <Typography>{getTrayWidth(columnWidth)} cm</Typography>
      </LabelContainer>
      <LabelContainer label="Festes Tablar" radios style={{ marginTop: '8px' }}>
        <Checkbox
          style={{ marginLeft: '-12px' }}
          checked={isFixTray}
          onChange={(e, value) => item.setProperty(ItemProperties.IsFixTray, value)}
          disabled={!item.draggable}
        />
      </LabelContainer>
    </>
  )
}
