import { Button } from '@mui/material'
import { LayoutProps } from '@teamsesam/configurator-core'
import { Horizontal, Vertical } from '@teamsesam/core'
import { useState } from 'react'
import { ConstructionProperties } from './ConstructionProperties'
import { InteriorProperties } from './interior-properties/InteriorProperties'
import { PartsListDialog } from './PartsListDialog'

export function Properties(props: LayoutProps) {
  const [open, setOpen] = useState(false)

  return (
    <Vertical>
      <ConstructionProperties />
      <InteriorProperties />

      <Button onClick={() => setOpen(true)} size="large" color="primary" style={{ position: 'absolute', left: '22px', bottom: '18px' }}>
        Stückliste
      </Button>
      {open && <PartsListDialog onClose={() => setOpen(false)} />}
      <Horizontal spacing={0.5} style={{ position: 'absolute', right: '32px', bottom: '24px', color: '#bababa' }}>
        <a href="tel:+41442159500">+41 44 215 95 00</a>
        <span>|</span>
        <a href="mailto:info@wbform.ch">info@wbform.ch</a>
      </Horizontal>
    </Vertical>
  )
}
