import { useMemo, useState } from 'react'
import { Button, DialogContent, DialogTitle, IconButton } from '@mui/material'
import {
  AutoOrNumber,
  ColumnGridConstruction,
  Item,
  useColumnGridProperties,
  useItemProperties,
  useRootItem,
  useSetCameraView,
} from '@teamsesam/configurator-core'
import { LabelContainer } from './LabelContainer'
import { ItemProperties } from '../three/config'
import {
  columnWidths,
  maxColumnWidth,
  maxShelfHeight,
  minColumnWidth,
  minShelfHeight,
  plateThickness,
  sideHeights,
} from '../three/constants'
import { CoreDialog, Horizontal, LabelSelect, LabelSelectOption, Vertical } from '@teamsesam/core'
import { MinMaxNumberField } from './MinMaxNumberField'
import DeleteIcon from '@mui/icons-material/DeleteOutline'

interface EditColumnDialogProps {
  item: Item
  onClose: () => void
}

export const EditColumnDialog = (props: EditColumnDialogProps) => {
  const { item, onClose } = props
  const { setColumnHeight, setColumnWidth, removeColumn } = useColumnGridProperties()
  const shelfItem = useRootItem()
  const { useStandardLengths, fixColumnHeights, fixColumnWidths, gridConstruction } = useItemProperties(shelfItem, [
    ItemProperties.UseStandardLengths,
    ItemProperties.FixColumnHeights,
    ItemProperties.FixColumnWidths,
    ItemProperties.GridConstruction,
  ]) as {
    useStandardLengths: boolean
    fixColumnHeights: boolean
    fixColumnWidths: boolean
    gridConstruction: ColumnGridConstruction
  }
  const { columnIndex } = useItemProperties(item, [ItemProperties.ColumnIndex])
  const [height, setHeight] = useState<AutoOrNumber>(fixColumnHeights ? gridConstruction.columns[columnIndex].height : 'auto')
  const [width, setWidth] = useState<AutoOrNumber>(fixColumnWidths ? gridConstruction.columns[columnIndex].width : 'auto')
  const setCameraView = useSetCameraView()

  const setColumnDimension = () => {
    if (height !== 'auto') {
      setColumnHeight(columnIndex, height)
      shelfItem.setProperty(ItemProperties.FixColumnHeights, true)
    }
    if (width !== 'auto') {
      setColumnWidth(columnIndex, width)
      shelfItem.setProperty(ItemProperties.FixColumnWidths, true)
    }
    setCameraView('default')
    onClose()
  }

  const removeCurrentColumn = () => {
    removeColumn(columnIndex)
    setCameraView('default')
    onClose()
  }

  const heightOptions = useMemo(() => {
    const heights: LabelSelectOption[] = fixColumnHeights ? [] : [{ label: 'auto', value: 'auto' }]
    heights.push(...sideHeights.map((sideHeight) => ({ label: `${sideHeight} cm`, value: sideHeight })))
    return heights
  }, [fixColumnHeights])

  const widthOptions = useMemo(() => {
    const widths: LabelSelectOption[] = fixColumnWidths ? [] : [{ label: 'auto', value: 'auto' }]
    widths.push(...columnWidths.map((columnWidth) => ({ label: `${columnWidth - 2} cm`, value: columnWidth })))
    return widths
  }, [fixColumnWidths])

  return (
    <CoreDialog open={true} onClose={onClose} showCloseButton>
      <DialogTitle>Spalte</DialogTitle>
      <DialogContent style={{ paddingLeft: '24px', paddingBottom: '24px', paddingRight: '24px' }}>
        <Vertical spacing={3}>
          <Vertical spacing={2}>
            <LabelContainer label="Höhe">
              {useStandardLengths ? (
                <LabelSelect value={height} onChange={(e) => setHeight(e.target.value as AutoOrNumber)} autoFocus options={heightOptions} />
              ) : (
                <MinMaxNumberField
                  min={minShelfHeight}
                  max={maxShelfHeight}
                  onChange={(value) => setHeight(value as number)}
                  value={height}
                  autoFocus
                />
              )}
            </LabelContainer>
            <LabelContainer label="Breite">
              {useStandardLengths ? (
                <LabelSelect value={width} onChange={(e) => setWidth(e.target.value as AutoOrNumber)} options={widthOptions} />
              ) : (
                <MinMaxNumberField
                  min={minColumnWidth}
                  max={maxColumnWidth}
                  onChange={(value) => setWidth((value as number) + plateThickness)}
                  value={width === 'auto' ? 'auto' : width - plateThickness}
                />
              )}
            </LabelContainer>
          </Vertical>
          <Horizontal spacing={6} sx={{ justifyContent: 'flex-end' }}>
            {fixColumnWidths && gridConstruction.columns.length > 1 && (
              <IconButton onClick={removeCurrentColumn} title="Spalte löschen" color="error" sx={{ marginLeft: '-10px' }}>
                <DeleteIcon />
              </IconButton>
            )}
            <Button variant="contained" color="primary" onClick={setColumnDimension}>
              Übernehmen
            </Button>
          </Horizontal>
        </Vertical>
      </DialogContent>
    </CoreDialog>
  )
}
