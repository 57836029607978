import { useEffect, useRef } from 'react'
import { Button, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Horizontal, Vertical } from '@teamsesam/core'
import {
  ConfigurationItem,
  Controller,
  Item,
  useConfigItems,
  useInstance,
  useItemProperties,
  useRootItem,
  useSelectedItem,
} from '@teamsesam/configurator-core'
import { ItemProperties, ItemType } from '../../three/config'
import { BackWallOptions } from './BackWallOptions'
import { DoorOptions } from './DoorOptions'
import { DrawerOptions } from './DrawerOptions'
import { FlapOptions } from './FlapOptions'
import { RollingDrawerOptions } from './RollingDrawerOptions'
import { TrayOptions } from './TrayOptions'

export interface ItemOptionsProps {
  item: Item
}

interface DraggableInteriorProps {
  configItem: ConfigurationItem
  selectedItem: Item | undefined
  shelfDepth: number
  hasShelfBackWall: boolean
}

function getDisabledMessage(typeId: string, shelfDepth: number, hasShelfBackWall: boolean): string | undefined {
  if (typeId === ItemType.InteriorBackWall && hasShelfBackWall) {
    return 'Nicht bei Rückwand'
  }
  if (typeId === ItemType.RollingDrawer && shelfDepth === 22.5) {
    return 'Nicht für Tiefe 22.5cm'
  }
  if (typeId === ItemType.Drawer && shelfDepth === 22.5) {
    return 'Nicht für Tiefe 22.5cm'
  }
  return undefined
}

function DraggableInterior(props: DraggableInteriorProps) {
  const { configItem, selectedItem, shelfDepth, hasShelfBackWall } = props
  const controller = useInstance(Controller)
  const element = useRef<HTMLImageElement>(null)
  const disabledMessage = getDisabledMessage(configItem.typeId, shelfDepth, hasShelfBackWall)

  useEffect(() => {
    const currentElement = element.current
    if (currentElement) {
      controller.addDraggableItem(currentElement)
      return () => controller.removeDraggableItem(currentElement)
    }
  }, [controller])

  return (
    <div
      style={{
        position: 'relative',
        padding: '8px 8px 4px 8px',
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none',
        backgroundColor: selectedItem && selectedItem.isType(configItem.typeId) ? '#f8f6f7' : '#ffffff',
        pointerEvents: disabledMessage ? 'none' : 'auto',
      }}
    >
      <img
        className="dragging-img"
        id={configItem.typeId}
        ref={element}
        draggable={false}
        onDragStart={(e) => e.preventDefault()}
        src={configItem.icon}
        alt={configItem.displayName}
        style={{ opacity: disabledMessage ? 0.2 : 1.0 }}
      />
      {!selectedItem && (
        <Typography
          align="center"
          style={{
            fontSize: '12px',
            color: '#7F7F7F',
            marginTop: '8px',
            pointerEvents: 'none',
            opacity: disabledMessage ? 0.35 : 1.0,
          }}
        >
          {configItem.displayName}
        </Typography>
      )}
      {disabledMessage && (
        <Typography variant="body2" align="center" style={{ fontSize: '12px', position: 'absolute', left: 0, width: '100%' }}>
          {disabledMessage}
        </Typography>
      )}
    </div>
  )
}

export function InteriorProperties() {
  const configItems = useConfigItems().filter((item) => item.category === 'draggable')
  const { selectedItem } = useSelectedItem()
  const shelfItem = useRootItem()
  const { shelfDepth, hasShelfBackWall } = useItemProperties(shelfItem, [ItemProperties.ShelfDepth, ItemProperties.HasShelfBackWall])
  useItemProperties(selectedItem, [ItemProperties.IsFixTray])

  return (
    <Vertical>
      <Typography style={{ paddingLeft: '24px', paddingTop: '24px', paddingBottom: '16px', fontWeight: 700 }}>AUSSTATTUNG</Typography>
      <Horizontal>
        {configItems.map((configItem) => (
          <DraggableInterior
            key={configItem.typeId}
            configItem={configItem}
            selectedItem={selectedItem}
            shelfDepth={shelfDepth}
            hasShelfBackWall={hasShelfBackWall}
          />
        ))}
      </Horizontal>
      {selectedItem && (
        <Vertical
          style={{
            position: 'relative',
            backgroundColor: '#f8f6f7',
            paddingLeft: '32px',
            paddingTop: '8px',
            paddingBottom: '24px',
            paddingRight: '8px',
          }}
          spacing={2}
        >
          <Horizontal style={{ justifyContent: 'space-between', marginBottom: '-10px', height: '33px' }}>
            <Typography color="primary" style={{ textTransform: 'uppercase' }}>
              {selectedItem.configItem.displayName}
            </Typography>
            {selectedItem.draggable && (
              <Button
                startIcon={<DeleteIcon />}
                onClick={() => (selectedItem.parent ? selectedItem.parent.remove(selectedItem) : {})}
                sx={{ color: '#F24726', fontSize: '12px' }}
              >
                Entfernen
              </Button>
            )}
          </Horizontal>
          {selectedItem.isType(ItemType.Drawer) && <DrawerOptions item={selectedItem} />}
          {selectedItem.isType(ItemType.DoorSet) && <DoorOptions item={selectedItem} />}
          {selectedItem.isType(ItemType.FlapSet) && <FlapOptions item={selectedItem} />}
          {selectedItem.isType(ItemType.Tray) && <TrayOptions item={selectedItem} />}
          {selectedItem.isType(ItemType.RollingDrawer) && <RollingDrawerOptions item={selectedItem} />}
          {selectedItem.isType(ItemType.InteriorBackWall) && <BackWallOptions item={selectedItem} />}
        </Vertical>
      )}
    </Vertical>
  )
}
