import { InteractionBehavior, Item, ItemContext } from '@teamsesam/configurator-core'
import { columnWidths, frontClearance, frontPlateThickness } from '../constants'
import { DoorHinge, ItemProperties, ItemType } from '../config'
import { updateBackWalls, updateDropBoxByDraggableItemResize } from './columnDropBox'

export const doorSet = (context: ItemContext) => {
  const { item, on, setInteractionBehavior, createItem } = context

  let leftDoor: Item | undefined
  let rightDoor: Item | undefined

  const updateDoorSet = () => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth) as number
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth) as number
    const hinge = item.getPropertyValue(ItemProperties.DoorHinge) as DoorHinge
    const doorHeight = item.getPropertyValue(ItemProperties.DoorHeight)

    const isDoubleDoor = columnWidth > 80
    const doorWidth = isDoubleDoor ? (columnWidth - frontClearance - 0.2) / 2 : columnWidth - frontClearance
    const doorX = isDoubleDoor ? (doorWidth + 0.1) / 2 : 0
    const doorZ = (shelfDepth + frontPlateThickness) / 2

    if (isDoubleDoor || hinge === DoorHinge.Left) {
      if (!leftDoor) {
        leftDoor = createItem(ItemType.Door, {
          properties: {
            [ItemProperties.DoorHinge]: DoorHinge.Left,
            [ItemProperties.DoorHeight]: doorHeight,
            [ItemProperties.DoorWidth]: doorWidth,
          },
        })
        item.add(leftDoor)
      } else {
        leftDoor.setProperties({
          [ItemProperties.DoorHeight]: doorHeight,
          [ItemProperties.DoorWidth]: doorWidth,
        })
      }
      leftDoor.position.x = isDoubleDoor ? -doorX : doorX
      leftDoor.position.z = doorZ
    } else {
      if (leftDoor) {
        item.remove(leftDoor)
        leftDoor = undefined
      }
    }

    if (isDoubleDoor || hinge === DoorHinge.Right) {
      if (!rightDoor) {
        rightDoor = createItem(ItemType.Door, {
          properties: {
            [ItemProperties.DoorHinge]: DoorHinge.Right,
            [ItemProperties.DoorHeight]: doorHeight,
            [ItemProperties.DoorWidth]: doorWidth,
          },
        })
        item.add(rightDoor)
      } else {
        rightDoor.setProperties({
          [ItemProperties.DoorHeight]: doorHeight,
          [ItemProperties.DoorWidth]: doorWidth,
        })
      }
      rightDoor.position.x = doorX
      rightDoor.position.z = doorZ
    } else {
      if (rightDoor) {
        item.remove(rightDoor)
        rightDoor = undefined
      }
    }

    item.updateBoundingBox()
  }

  const updateDoorSetHeight = (doorHeight: number) => {
    if (leftDoor) {
      leftDoor.setProperty(ItemProperties.DoorHeight, doorHeight)
    }
    if (rightDoor) {
      rightDoor.setProperty(ItemProperties.DoorHeight, doorHeight)
    }

    item.updateBoundingBox()
    updateBackWalls(item.parentItem, createItem)
    updateDropBoxByDraggableItemResize(item)
  }

  updateDoorSet()

  on.propertyChanged([ItemProperties.ColumnWidth, ItemProperties.ShelfDepth, ItemProperties.DoorHinge], updateDoorSet)
  on.propertyChanged([ItemProperties.DoorHeight], updateDoorSetHeight)

  on.selected(() => {
    if (leftDoor) {
      leftDoor.itemAnimations.startAnimation('open')
    }
    if (rightDoor) {
      rightDoor.itemAnimations.startAnimation('open')
    }
    return () => {
      if (leftDoor) {
        leftDoor.itemAnimations.reverseAnimation('open')
      }
      if (rightDoor) {
        rightDoor.itemAnimations.reverseAnimation('open')
      }
    }
  })

  on.validateDropBox((dropBox) => {
    if (!columnWidths.includes(dropBox.size.x)) {
      return 'Türen nur für Standardmasse'
    }
    return undefined
  })

  setInteractionBehavior(InteractionBehavior.SelectableAndDraggable)
}
