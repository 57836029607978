import * as THREE from 'three'

export class TextureCache {
  private textures: { [url: string]: THREE.Texture } = {}
  private whenAllLoadedCallbacks: (() => void)[] = []

  constructor(urls: any[]) {
    this.append(urls)
  }

  public append(urls: any[]) {
    const loader = new THREE.TextureLoader()
    for (const url of urls) {
      const texture = loader.load(url, () => this.textureLoaded())
      this.textures[url] = texture
    }
  }

  private textureLoaded() {
    this.allLoaded = Object.values(this.textures).every((texture) => texture.image !== undefined)
    if (this.allLoaded) {
      this.whenAllLoadedCallbacks.forEach((callback) => callback())
    }
  }

  public allLoaded = false

  public whenAllLoaded(callback: () => void) {
    if (this.allLoaded) {
      callback()
    } else {
      this.whenAllLoadedCallbacks.push(callback)
    }
  }

  public get(url: string, rotation: number) {
    if (rotation !== 0) {
      const texture = this.textures[url].clone()
      texture.center.x = 0.5
      texture.center.y = 0.5
      texture.rotation = rotation
      texture.needsUpdate = true
      return texture
    }
    return this.textures[url]
  }

  public static default: TextureCache

  public static appendToDefault(urls: any[]) {
    if (!TextureCache.default) {
      TextureCache.default = new TextureCache(urls)
    } else {
      TextureCache.default.append(urls)
    }
  }
}
