import React from 'react'
import { Typography } from '@mui/material'
import { useItemProperties } from '@teamsesam/configurator-core'
import { ItemProperties } from '../../three/config'
import { LabelContainer } from '../LabelContainer'
import { getInteriorBackWallWidth } from '../../three/constants'
import { ItemOptionsProps } from './InteriorProperties'
import { LabelSelect } from '@teamsesam/core'

export function BackWallOptions(props: ItemOptionsProps) {
  const { item } = props
  const { columnWidth, interiorBackWallHeight } = useItemProperties(item, [ItemProperties.ColumnWidth, ItemProperties.InteriorBackWallHeight])
  return (
    <>
      <LabelContainer label="Höhe">
        <LabelSelect
          value={interiorBackWallHeight}
          onChange={(e) => item.setProperty(ItemProperties.InteriorBackWallHeight, e.target.value)}
          options={[
            { value: 45, label: '45 cm' },
            { value: 54, label: '54 cm' },
            { value: 63, label: '63 cm' },
            { value: 90, label: '90 cm' },
          ]}
        />
      </LabelContainer>
      <LabelContainer label="Breite">
        <Typography>{getInteriorBackWallWidth(columnWidth)} cm</Typography>
      </LabelContainer>
    </>
  )
}
