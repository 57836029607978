import * as THREE from 'three'
import { shadowOffset, ShadowTexureForUvUpdateOptions, ShadowWithUvUpdateMesh } from '../shadowMesh'

export class InnerRearShadow extends ShadowWithUvUpdateMesh {
  constructor(options: ShadowTexureForUvUpdateOptions) {
    super('right', 'top', options)
  }

  protected getShadowSize(itemSize: THREE.Vector3): THREE.Vector2 {
    return new THREE.Vector2(itemSize.x, itemSize.y)
  }

  protected setPosition(size: THREE.Vector3, shadowSize: THREE.Vector2): void {
    this.position.z = size.z / -2 + shadowOffset
  }
}
