import {
  usePublishProduct,
  useSendMail,
  useSerializeItemsAndPropertyDefaultValues,
  useScreenshot,
  ScreenshotType,
  getCompositonUrl,
  useRootItem,
  Item,
} from '@teamsesam/configurator-core'
import { useCallback } from 'react'
import { useSetView } from './useSetView'
import { PartsListPosition, usePartsListAndPrices } from './usePartsListAndPrices'
import { OrderFormData } from '../components/OrderDialog'
import { ItemProperties, ItemType } from '../three/config'
import _ from 'lodash'
import { plateThickness } from '../three/constants'
import { useMailTestMode } from '../components/MailTestModeIndicator'
import { useFormatPrice } from './useFormatPrice'

export const mailPartsList = (partsList: PartsListPosition[], totalPrice: number, formatPrice: (amount: number) => string) => `
<p>
  <table>
    <tr>
      <td style="padding: 2px 4px 2px 0; font-style: italic">Artikel-Nr.</td>
      <td style="padding: 2px 4px; font-style: italic">Bezeichnung</td>
      <td style="padding: 2px 4px; font-style: italic">Masse</td>
      <td style="padding: 2px 4px; font-style: italic" align="right">Anz.</td>
      <td style="padding: 2px 4px; font-style: italic" align="right">Preis</td>
      <td style="padding: 2px 4px; font-style: italic" align="right">Total</td>
    </tr>
    ${partsList
      .map(
        (position) => `
      <tr>
        <td style="padding: 2px 4px 2px 0">${position.articleNr}</td>
        <td style="padding: 2px 4px">${position.name}</td>
        <td style="padding: 2px 4px">${position.dimension}</td>
        <td style="padding: 2px 4px" align="right">${position.quantity}</td>
        <td style="padding: 2px 4px" align="right">${formatPrice(position.price)}</td>
        <td style="padding: 2px 4px" align="right">${formatPrice(position.totalPrice)}</td>
      </tr>
    `,
      )
      .join('')}
    <tr>
      <td colspan="5" align="right" style="padding-right: 12px"><b>Total inkl. MwSt / exkl. Montage</b></td>
      <td align="right" style="border-top: solid 1px; border-bottom: double 1px; padding-right: 4px"><b>${formatPrice(
        totalPrice,
      )}</b></td>
    </tr>
  </table>
</p>
`

const mailBody = (
  loadDesignUrl: string,
  data: OrderFormData,
  partsList: PartsListPosition[],
  totalPrice: number,
  formatPrice: (amount: number) => string,
  shelfWidth: number,
  shelfHeight: number,
  shelfDepth: number,
) => `
<html>
<head>
</head>
<body>
<div style="width:600px; height: 400px; display: flex;">
  <img width="600" height="400" src="cid:screenshot3D" alt="UPW-Regal" />
</div>

<p>
  Dimension: ${shelfWidth} x ${shelfHeight} x ${shelfDepth} cm (BxHxT)
</p>

<p>
  Es wurde unter folgendem Link gespeichert und kann jederzeit weiterbearbeitet werden.<br/>
  <a href="${loadDesignUrl}">${loadDesignUrl}</a>
</p>

${mailPartsList(partsList, totalPrice, formatPrice)}

<p>
  Bestell-Nr. / Kommission: ${data.orderNr}<br/>
  <br/>
  Ich bin ${data.customerType}<br/>
  <br/>
  Firma: ${data.company}<br/>
  Name: ${data.name}<br/>
  Lieferadresse:<br/>
  ${data.deliveryStreet}<br/>
  ${data.deliveryZipCode} ${data.deliveryCity}<br/>
  Telefon: ${data.phone}<br/>
  <br/>
  Alternative Rechnungsadresse: ${data.billingAddress}<br/>
  <br/>
  Bemerkungen:<br/>
  ${data.remark ? data.remark.replaceAll('\n', '<br/>') : ''}<br/>
  ${data.subscribeToNewsletter ? '<br/>Ja, ich möchte den Newsletter abonnieren<br/>' : ''}
</p>

<p>Mit freundlichen Grüssen<br />
Ihr wb form Team</p>

<p>wb form ag<br />
Uraniastrasse 40 | CH-8001 Zürich<br />
<a href="tel:+41442159500">+41 44 215 95 00</a> | <a href="mailto:info@wbform.ch">info@wbform.ch</a></p>
<a href="https://www.wbform.com/">www.wbform.com</a>
</body>
</html>
`

export const getShelfWidth = (shelfItem: Item): number => {
  const columns = shelfItem.getItems(ItemType.ColumnDropBox)
  const columnsTotalWidth = _.sum(columns.map((column) => column.getPropertyValue(ItemProperties.ColumnWidth) as number))
  return columnsTotalWidth + plateThickness
}

export const getShelfHeight = (shelfItem: Item): number => {
  const columns = shelfItem.getItems(ItemType.ColumnDropBox)
  return _.max(columns.map((column) => column.getPropertyValue(ItemProperties.ColumnHeight) as number)) || 0
}

export const useCreateOrder = () => {
  const { set3DView } = useSetView()
  const saveProduct = usePublishProduct()
  const sendMail = useSendMail()
  const serialize = useSerializeItemsAndPropertyDefaultValues()
  const takeScreenshot = useScreenshot()
  const { totalPrice, positions } = usePartsListAndPrices()
  const shelfItem = useRootItem()
  const formatPrice = useFormatPrice()
  const mailTestMode = useMailTestMode()

  return useCallback(
    async (data: OrderFormData) => {
      const color = shelfItem.getPropertyValue(ItemProperties.ShelfMaterial)
      const shelfDepth = shelfItem.getPropertyValue(ItemProperties.ShelfDepth)
      const shelfWidth = getShelfWidth(shelfItem)
      const shelfHeight = getShelfHeight(shelfItem)
      const productTitle = `Bestellung UPW ${color} (${shelfWidth} x ${shelfHeight} x ${shelfDepth} cm)`

      set3DView()
      const productScreenshot = takeScreenshot(ScreenshotType.ByteString, { resolution: { x: 1920, y: 1080 } })
      const emailScreenshot = takeScreenshot(ScreenshotType.Default, { resolution: { x: 1920, y: 1080 } })

      const content = serialize()
      const product = await saveProduct({
        title: productTitle,
        net: totalPrice,
        state: 'in-basket',
        images: [{ attachment: productScreenshot }],
        reference: data,
        compositions: [{ content }],
      })

      await sendMail({
        to: data.email,
        cc: mailTestMode ? data.email : 'info@wbform.ch',
        subject: productTitle,
        html: mailBody(
          getCompositonUrl(product.compositions[0]),
          data,
          positions,
          totalPrice,
          formatPrice,
          shelfWidth,
          shelfHeight,
          shelfDepth,
        ),
        attachments: [
          { cid: 'screenshot3D', path: emailScreenshot },
          { filename: `UPW-3d.png`, path: emailScreenshot },
        ],
      })
    },
    [formatPrice, mailTestMode, positions, saveProduct, sendMail, serialize, set3DView, shelfItem, takeScreenshot, totalPrice],
  )
}
