import { ConfigurationItem, Item } from '@teamsesam/configurator-core'
import { getArticle } from './articles'
import { ItemProperties, ItemType, SideWallType } from './config'
import {
  flapHeight,
  getDoorWidth,
  getDrawerDepth,
  getDrawerWidth,
  getFixTrayDepth,
  getFlapWidth,
  getInteriorBackWallWidth,
  getNextStandardColumnWidth,
  getNextStandardSideWallHeight,
  getPlinthPanelWidth,
  getRollingDrawerDepth,
  getRollingDrawerWidth,
  getTraverseWidth,
  getTrayDepth,
  getTrayWidth,
  isDoubleDoor,
  isStandardColumnWidth,
  isStandardSideWallHeight,
} from './constants'
import {
  getDoorArticleNr,
  getDrawerArticleNr,
  getFlapArticleNr,
  getInteriorBackWallArticleNr,
  getPlinthPanelArticleNr,
  getRollingDrawerArticleNr,
  getSideWallArticleNr,
  getTraverseArticleNr,
  getTrayArticleNr,
} from './getArticleNr'

export interface ArticlePriceAndDescription {
  articleNr: string
  name: string
  dimension: string
  price: number
}

const colorPriceFactor = (item: ConfigurationItem): number => {
  const material = item.properties[ItemProperties.ShelfMaterial].value
  return material === 'Schwarz' ? -1 : 1
}

const getDoorPriceAndDescription = (item: Item): ArticlePriceAndDescription => {
  const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth)
  const width = getDoorWidth(columnWidth)
  const height = item.getPropertyValue(ItemProperties.DoorHeight)
  const isDouble = isDoubleDoor(columnWidth)

  const articleNr = getDoorArticleNr(width, height)
  const name = isDouble ? 'Doppeltüre' : 'Türe'
  const dimension = `${height} x ${width} cm`

  const article = getArticle(articleNr)

  return {
    articleNr,
    name,
    dimension,
    price: article.price * colorPriceFactor(item.configItem),
  }
}

const getDrawerPriceAndDescription = (item: Item): ArticlePriceAndDescription => {
  const width = getDrawerWidth(item.getPropertyValue(ItemProperties.ColumnWidth))
  const depth = getDrawerDepth(item.getPropertyValue(ItemProperties.ShelfDepth))
  const height = item.getPropertyValue(ItemProperties.DrawerHeight)

  const articleNr = getDrawerArticleNr(width, height, depth)
  const name = `Schublade ${height > 9 ? 'hoch' : 'flach'}`
  const dimension = `${width} x ${depth} cm`

  const article = getArticle(articleNr)

  return {
    articleNr,
    name,
    dimension,
    price: article.price * colorPriceFactor(item.configItem),
  }
}

const getFlapPriceAndDescription = (item: Item): ArticlePriceAndDescription => {
  const width = getFlapWidth(item.getPropertyValue(ItemProperties.ColumnWidth))

  const articleNr = getFlapArticleNr(width)
  const name = 'Klappe'
  const dimension = `${flapHeight} x ${width} cm`

  const article = getArticle(articleNr)

  return {
    articleNr,
    name,
    dimension,
    price: article.price * colorPriceFactor(item.configItem),
  }
}

const getInteriorBackWallPriceAndDescription = (item: Item): ArticlePriceAndDescription => {
  let width = getInteriorBackWallWidth(item.getPropertyValue(ItemProperties.ColumnWidth))
  let height = item.getPropertyValue(ItemProperties.InteriorBackWallHeight)

  if (height > width) {
    ;[width, height] = [height, width]
  }

  const articleNr = getInteriorBackWallArticleNr(width, height)
  const name = 'Rückwand 7mm'
  const dimension = `${height} x ${width} cm`

  const article = getArticle(articleNr)

  return {
    articleNr,
    name,
    dimension,
    price: article.price * colorPriceFactor(item.configItem),
  }
}

const getRollingDrawerPriceAndDescription = (item: Item): ArticlePriceAndDescription => {
  const width = getRollingDrawerWidth(item.getPropertyValue(ItemProperties.ColumnWidth))
  const depth = getRollingDrawerDepth(item.getPropertyValue(ItemProperties.ShelfDepth))

  const articleNr = getRollingDrawerArticleNr(width, depth)
  const name = 'Rollschublade'
  const dimension = `${width} x ${depth} cm`

  const article = getArticle(articleNr)

  return {
    articleNr,
    name,
    dimension,
    price: article.price * colorPriceFactor(item.configItem),
  }
}

const getShelfBackWallPriceAndDescription = (item: Item): ArticlePriceAndDescription => {
  const width = item.getPropertyValue(ItemProperties.ColumnWidth)
  const height = item.getPropertyValue(ItemProperties.ShelfBackWallHeight) as number

  const articleNr = '602534'
  const name = 'Rückwand Hartfaser 3mm'
  const dimension = `${width} x ${height} cm`

  const article = getArticle(articleNr)

  const area = (width / 100) * (height / 100)

  return {
    articleNr,
    name,
    dimension,
    price: article.price * area * colorPriceFactor(item.configItem),
  }
}

const getSideWallPriceAndDescription = (item: Item): ArticlePriceAndDescription => {
  const height = item.getPropertyValue(ItemProperties.SideWallHeight)
  const depth = item.getPropertyValue(ItemProperties.ShelfDepth)
  const type = item.getPropertyValue(ItemProperties.SideWallType)

  const isStandardHeight = isStandardSideWallHeight(height)
  const standardSideWallHeight = isStandardHeight ? height : getNextStandardSideWallHeight(height)

  const articleNr = getSideWallArticleNr(standardSideWallHeight, depth, type)
  const name = `Seitenwand ${type === SideWallType.Middle ? 'beidseitig' : 'einseitig'} gelocht`
  const dimension = `${height} x ${depth} cm`

  const article = getArticle(articleNr)

  let price = article.price
  if (!isStandardHeight) {
    price += type === SideWallType.Middle ? getArticle('263967').price : getArticle('263964').price
  }

  return {
    articleNr,
    name,
    dimension,
    price: price * colorPriceFactor(item.configItem),
  }
}

const getTraversePriceAndDescription = (item: Item): ArticlePriceAndDescription => {
  const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth)
  const width = getTraverseWidth(columnWidth)

  const isStandardWidth = isStandardColumnWidth(columnWidth)
  const standardColumnWidth = isStandardWidth ? columnWidth : getNextStandardColumnWidth(columnWidth)

  const articleNr = getTraverseArticleNr(getTraverseWidth(standardColumnWidth))
  const name = 'Traverse'
  const dimension = `${width} cm`

  const article = getArticle(articleNr)

  let price = article.price
  if (!isStandardWidth) {
    price += getArticle('263968').price
  }

  return {
    articleNr,
    name,
    dimension,
    price: price * colorPriceFactor(item.configItem),
  }
}

const getTrayPriceAndDescription = (item: Item): ArticlePriceAndDescription => {
  const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth)
  const depth = item.getPropertyValue(ItemProperties.ShelfDepth)
  const isFix = item.getPropertyValue(ItemProperties.IsFixTray)
  const width = getTrayWidth(columnWidth)

  const trayDepth = isFix ? getFixTrayDepth(depth) : getTrayDepth(depth)
  const isStandardWidth = isStandardColumnWidth(columnWidth)
  const standardColumnWidth = isStandardWidth ? columnWidth : getNextStandardColumnWidth(columnWidth)

  const articleNr = getTrayArticleNr(getTrayWidth(standardColumnWidth), trayDepth, isFix)
  const name = isFix ? 'Boden (festes Tablar)' : 'Verstellbares Tablar'
  const dimension = `${width} x ${trayDepth} cm`

  const article = getArticle(articleNr)

  let price = article.price
  if (!isStandardWidth) {
    price += isFix ? getArticle('263965').price : getArticle('263967_2').price
  }

  return {
    articleNr,
    name,
    dimension,
    price: price * colorPriceFactor(item.configItem),
  }
}

const getPlinthPanelPriceAndDescription = (item: Item): ArticlePriceAndDescription => {
  const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth)
  const width = getPlinthPanelWidth(columnWidth)

  const isStandardWidth = isStandardColumnWidth(columnWidth)
  const standardColumnWidth = isStandardWidth ? columnWidth : getNextStandardColumnWidth(columnWidth)

  const articleNr = getPlinthPanelArticleNr(getPlinthPanelWidth(standardColumnWidth))
  const name = 'Sockel ohne Falz'
  const dimension = `${width} cm`

  const article = getArticle(articleNr)

  let price = article.price
  if (!isStandardWidth) {
    price += getArticle('602371_2').price
  }

  return {
    articleNr,
    name,
    dimension,
    price: price * colorPriceFactor(item.configItem),
  }
}

export const getArticlePriceAndDescription = (item: Item): ArticlePriceAndDescription | undefined => {
  switch (item.configItem.typeId) {
    case ItemType.DoorSet:
      return getDoorPriceAndDescription(item)
    case ItemType.Drawer:
      return getDrawerPriceAndDescription(item)
    case ItemType.FlapSet:
      return getFlapPriceAndDescription(item)
    case ItemType.InteriorBackWall:
      return getInteriorBackWallPriceAndDescription(item)
    case ItemType.RollingDrawer:
      return getRollingDrawerPriceAndDescription(item)
    case ItemType.ShelfBackWall:
      return getShelfBackWallPriceAndDescription(item)
    case ItemType.SideWall:
      return getSideWallPriceAndDescription(item)
    case ItemType.Traverse:
      return getTraversePriceAndDescription(item)
    case ItemType.Tray:
      return getTrayPriceAndDescription(item)
    case ItemType.PlinthPanel:
      return getPlinthPanelPriceAndDescription(item)
  }
}
