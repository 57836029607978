import * as THREE from 'three'
import { TextureCache } from './textureCache'

export interface ShadowTexureOptions {
  texture: any
  scale?: THREE.Vector2
  rotation?: number
  intensity?: number
}

export interface ShadowTexureForUvUpdateOptions extends ShadowTexureOptions {
  realWorldSize: THREE.Vector2
}

type PinX = 'left' | 'right'
type PinY = 'top' | 'bottom'

export const shadowOffset = 0.1

export abstract class ShadowMesh extends THREE.Mesh {
  constructor(protected options: ShadowTexureOptions) {
    super(
      new THREE.PlaneGeometry(1, 1),
      ShadowMesh.createShadowMaterial(options.texture, options.rotation || 0, options.intensity || 1),
    )
  }

  protected abstract setPosition(itemSize: THREE.Vector3, shadowSize: THREE.Vector2): void
  protected abstract getShadowSize(itemSize: THREE.Vector3): THREE.Vector2

  private static createShadowMaterial = (textureUrl: any, textureRotation: number, intensity: number) => {
    const alphaMap = TextureCache.default.get(textureUrl, textureRotation)
    return new THREE.MeshBasicMaterial({ color: 0x0, alphaMap, transparent: true, opacity: intensity, depthWrite: false })
  }

  public setSize(itemSize: THREE.Vector3) {
    const shadowSize = this.getShadowSize(itemSize)
    if (this.options.scale) {
      shadowSize.multiply(this.options.scale)
    }
    this.scale.x = shadowSize.x
    this.scale.y = shadowSize.y
    this.setPosition(itemSize, shadowSize)
  }
}

export abstract class ShadowWithUvUpdateMesh extends ShadowMesh {
  constructor(private pinX: PinX, private pinY: PinY, protected options: ShadowTexureForUvUpdateOptions) {
    super(options)
  }

  private static getUvX = (currentUv: number, factor: number, pin: PinX | PinY) => {
    if (pin === 'left' || pin === 'bottom') {
      return currentUv > 0 ? factor : 0
    }
    return currentUv < 1 ? 1 - factor : 1
  }

  private updateUvs() {
    const { realWorldSize } = this.options
    const geometry = this.geometry

    const size = new THREE.Box3().setFromObject(this).getSize(new THREE.Vector3())
    const xFactor = size.x > 0.1 ? size.x / realWorldSize.x : size.z / realWorldSize.x
    const yFactor = size.y > 0.1 ? size.y / realWorldSize.y : size.z / realWorldSize.y

    const uvAttribute = geometry.getAttribute('uv') as THREE.BufferAttribute
    for (let i = 0; i < uvAttribute.count; i++) {
      const x = ShadowWithUvUpdateMesh.getUvX(uvAttribute.getX(i), xFactor, this.pinX)
      const y = ShadowWithUvUpdateMesh.getUvX(uvAttribute.getY(i), yFactor, this.pinY)
      uvAttribute.setXY(i, x, y)
    }
    uvAttribute.needsUpdate = true
  }

  public setSize(itemSize: THREE.Vector3) {
    super.setSize(itemSize)
    this.updateUvs()
  }
}
