import {
  ImageLoader,
  registerBeforeUnload,
  ConfiguratorProvider,
  behavior,
  createConfigurator,
  setSceneBackgroundColor,
  backend,
  outlineSelectedItemRenderer,
  ConfiguratorOptions,
  interactionHistory,
} from '@teamsesam/configurator-core'
import { config, ItemType } from './three/config'
import React from 'react'
import { render } from 'react-dom'
import { Main } from './components/Main'
import * as THREE from 'three'
import { addCameras } from './three/behaviors/addCameras'
import { addLights } from './three/behaviors/addLights'
import { shelf } from './three/items/shelf'
import { sideWall } from './three/items/sideWall'
import { tray } from './three/items/tray'
import { columnDropBox } from './three/items/columnDropBox'
import { shelfBackWall } from './three/items/shelfBackWall'
import { drawer } from './three/items/drawer'
import { rollingDrawer } from './three/items/rollingDrawer'
import { flap } from './three/items/flap'
import { flapSet } from './three/items/flapSet'
import { door } from './three/items/door'
import { doorSet } from './three/items/doorSet'
import { interiorBackWall } from './three/items/interiorBackWall'
import { traverse } from './three/items/traverse'
import { innerMeasurement } from './three/items/innerMeasurement'
import { plinthPanel } from './three/items/plinthPanel'

const behaviors: behavior[] = [
  backend(process.env.REACT_APP_BACKEND_API_URL, process.env.REACT_APP_BACKEND_API_KEY),
  setSceneBackgroundColor(0xffffff),
  addCameras(),
  addLights,
  interactionHistory(),
]

if (process.env.NODE_ENV !== 'development') {
  behaviors.push(registerBeforeUnload())
}

const envMapImage = require('./assets/env-map/BackLight_2K_38_cube.jpg')
const imageLoader = new ImageLoader([], { envMapUrls: [envMapImage, envMapImage, envMapImage, envMapImage, envMapImage, envMapImage] })

const itemCreators = {
  [ItemType.Shelf]: shelf,
  [ItemType.SideWall]: sideWall,
  [ItemType.ColumnDropBox]: columnDropBox,
  [ItemType.ShelfBackWall]: shelfBackWall,
  [ItemType.Drawer]: drawer,
  [ItemType.RollingDrawer]: rollingDrawer,
  [ItemType.Tray]: tray,
  [ItemType.Flap]: flap,
  [ItemType.FlapSet]: flapSet,
  [ItemType.Door]: door,
  [ItemType.DoorSet]: doorSet,
  [ItemType.InteriorBackWall]: interiorBackWall,
  [ItemType.Traverse]: traverse,
  [ItemType.InnerMeasurement]: innerMeasurement,
  [ItemType.PlinthPanel]: plinthPanel,
}

const options: ConfiguratorOptions = {
  materialType: THREE.MeshPhysicalMaterial,
  renderer: outlineSelectedItemRenderer({
    selectedItem: {
      visibleEdgeColor: 0x2c7768,
    },
    hoverItem: {
      visibleEdgeColor: 0x2c7768,
    },
  }),
}

render(
  <React.StrictMode>
    <ConfiguratorProvider
      packageName={process.env.REACT_APP_PACKAGE}
      packageVersion={process.env.REACT_APP_VERSION}
      configurator={createConfigurator(config, itemCreators, imageLoader, behaviors, options)}
      options={{ elementId: 'three-canvas-container' }}
    >
      <Main />
    </ConfiguratorProvider>
  </React.StrictMode>,
  document.getElementById('app'),
)
