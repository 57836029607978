import { ItemContext } from '@teamsesam/configurator-core'
import { ItemProperties } from '../config'
import * as THREE from 'three'
import { shelfBackWallThickness } from '../constants'

export const shelfBackWall = (context: ItemContext) => {
  const { item, on, materials } = context

  const backWall = new THREE.Mesh(new THREE.BoxGeometry(1, 1, shelfBackWallThickness), materials.shelfMaterial)
  item.add(backWall)

  const updateBackWall = () => {
    const backWallHeight = item.getPropertyValue(ItemProperties.ShelfBackWallHeight) as number
    const backWallWidth = item.getPropertyValue(ItemProperties.ColumnWidth)
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth)

    backWall.scale.x = backWallWidth
    backWall.scale.y = backWallHeight
    backWall.position.z = (shelfDepth + shelfBackWallThickness) / -2

    item.updateBoundingBox()
  }

  updateBackWall()

  on.propertyChanged([ItemProperties.ShelfBackWallHeight, ItemProperties.ColumnWidth], updateBackWall)

  on.materialsChanged((materials) => (backWall.material = materials.shelfMaterial))
}
