import * as THREE from 'three'
import { ShadowMesh, ShadowTexureOptions } from '../shadowMesh'

export class FloorRightShadow extends ShadowMesh {
  constructor(options: ShadowTexureOptions) {
    super(options)
    this.rotateX(Math.PI / -2)
  }

  protected getShadowSize(itemSize: THREE.Vector3): THREE.Vector2 {
    return new THREE.Vector2(itemSize.y, itemSize.z)
  }

  protected setPosition(size: THREE.Vector3, shadowSize: THREE.Vector2): void {
    this.position.x = (size.x + shadowSize.x) / 2
    this.position.y = size.y / -2
    this.position.z = (shadowSize.y - size.z) / 2
  }
}
