import { Button } from '@mui/material'
import StraightenIcon from '@mui/icons-material/Straighten'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import { LayoutProps } from '@teamsesam/configurator-core'
import { useSetView } from '../hooks/useSetView'

export const SwitchCameraButton = (props: LayoutProps) => {
  const { camera, set2DView, set3DView } = useSetView()

  return (
    <div style={{ position: 'absolute', right: '32px', top: '40px', transform: 'translateY(-50%)' }}>
      {camera === '3D' && (
        <Button startIcon={<StraightenIcon />} onClick={set2DView}>
          Vermassung
        </Button>
      )}
      {camera === '2D' && (
        <Button startIcon={<ViewInArIcon />} onClick={set3DView}>
          3D-Ansicht
        </Button>
      )}
    </div>
  )
}
