import { useCamera, useOverlayView, useSetCameraView } from '@teamsesam/configurator-core'
import { useCallback } from 'react'

export enum Views {
    '3D' = '3D',
    '2D' = '2D',
  }

export const useSetView = () => {
  const { camera, setCamera } = useCamera()
  const setCameraView = useSetCameraView()
  const { setOverlayView } = useOverlayView()

  const set2DView = useCallback(() => {
    setCamera('2D')
    setCameraView('default')
    setOverlayView(Views['2D'])
  }, [setCamera, setCameraView, setOverlayView])

  const set3DView = useCallback(() => {
    setCamera('3D')
    setCameraView('default')
    setOverlayView(Views['3D'])
  }, [setCamera, setCameraView, setOverlayView])

  return { camera, set2DView, set3DView }
}
