import { AppConfiguration } from '@teamsesam/configurator-core'
import { drawerHeightFlat } from './constants'

export enum ItemType {
  Shelf = 'Shelf',
  SideWall = 'SideWall',
  Traverse = 'Traverse',
  ColumnDropBox = 'ColumnDropBox',
  ShelfBackWall = 'ShelfBackWall',
  Door = 'Door',
  DoorSet = 'DoorSet',
  Drawer = 'Drawer',
  RollingDrawer = 'RollingDrawer',
  Flap = 'Flap',
  FlapSet = 'FlapSet',
  InteriorBackWall = 'InteriorBackWall',
  Tray = 'Tray',
  InnerMeasurement = 'InnerMeasurement',
  PlinthPanel = 'PlinthPanel',
}

export enum ItemProperties {
  ShelfMaterial = 'shelfMaterial',
  InteriorMaterial = 'interiorMaterial',
  ShelfWidth = 'shelfWidth',
  ShelfHeight = 'shelfHeight',
  ShelfDepth = 'shelfDepth',
  DefaultTrayCount = 'defaultTrayCount',
  HasShelfBackWall = 'hasShelfBackWall',
  UseStandardLengths = 'useStandardLengths',
  GridConstruction = 'gridConstruction',
  FixColumnWidths = 'fixColumnWidths',
  FixColumnHeights = 'fixColumnHeights',
  SideWallHeight = 'sideWallHeight',
  SideWallType = 'sideWallType',
  ColumnWidth = 'columnWidth',
  ColumnHeight = 'columnHeight',
  ColumnIndex = 'columnIndex',
  DrawerHeight = 'drawerHeight',
  DoorHinge = 'doorHinge',
  DoorHeight = 'doorHeight',
  DoorWidth = 'doorWidth',
  ShelfBackWallHeight = 'shelfBackWallHeight',
  InteriorBackWallHeight = 'interiorBackWallHeight',
  IsFixTray = 'isFixTray',
  InnerMeasurementHeight = 'innerMeasurementHeight',
  WithPlinthPanel = 'withPlinthPanel',
}

export enum ShelfColor {
  Weiss = 'Weiss',
  Schwarz = 'Schwarz',
}

export enum SideWallType {
  Left = 'Left',
  Middle = 'Middle',
  Right = 'Right',
}

export enum DoorHinge {
  Left = 'Left',
  Right = 'Right',
}

const materials = {
  Weiss: {
    metalness: 0.36,
    roughness: 0.19,
    envMapIntensity: 0.19,
    reflectivity: 0.1,
  },
  Schwarz: {
    metalness: 0.5,
    roughness: 0.26,
    envMapIntensity: 0.68,
    reflectivity: 0,
  },
}

export const config: AppConfiguration = {
  general: {
    VATPercentage: 8.1,
    pricesInclVAT: true,
  },
  materialGroups: [
    {
      name: 'shelfMaterialGroup',
      memorizeLastValue: true,
      defaultValue: ShelfColor.Weiss,
      materials: [
        {
          name: ShelfColor.Weiss,
          color: '#F7F6F4',
          ...materials.Weiss,
        },
        {
          name: ShelfColor.Schwarz,
          color: '#2C3134',
          ...materials.Schwarz,
        },
      ],
    },
  ],
  properties: [
    {
      name: ItemProperties.ShelfMaterial,
      memorizeLastValue: true,
      defaultValue: ShelfColor.Weiss,
      materials: ['shelfMaterialGroup'],
    },
    {
      name: ItemProperties.InteriorMaterial,
      memorizeLastValue: true,
      defaultValue: ShelfColor.Weiss,
      materials: ['shelfMaterialGroup'],
    },
    {
      name: ItemProperties.ShelfHeight,
      defaultValue: 99,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.ShelfWidth,
      defaultValue: 101,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.ShelfDepth,
      defaultValue: 36,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.DefaultTrayCount,
      defaultValue: 4,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.HasShelfBackWall,
      defaultValue: false,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.UseStandardLengths,
      defaultValue: true,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.GridConstruction,
      defaultValue: {
        width: 101 - 2,
        columns: [
          { height: 99, width: 54 },
          { height: 99, width: 45 },
        ],
      },
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.FixColumnHeights,
      defaultValue: false,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.ColumnIndex,
      defaultValue: -1,
    },
    {
      name: ItemProperties.FixColumnWidths,
      defaultValue: false,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.SideWallType,
      defaultValue: SideWallType.Left,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.SideWallHeight,
      defaultValue: 207,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.ColumnWidth,
      defaultValue: 54,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.ColumnHeight,
      defaultValue: 207,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.DrawerHeight,
      defaultValue: drawerHeightFlat,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.DoorHinge,
      defaultValue: DoorHinge.Left,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.DoorHeight,
      defaultValue: 53,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.DoorWidth,
      defaultValue: 53,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.ShelfBackWallHeight,
      defaultValue: 10,
    },
    {
      name: ItemProperties.InteriorBackWallHeight,
      defaultValue: 45,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.IsFixTray,
      defaultValue: false,
      memorizeLastValue: true,
    },
    {
      name: ItemProperties.InnerMeasurementHeight,
      defaultValue: 100,
    },
    {
      name: ItemProperties.WithPlinthPanel,
      defaultValue: false,
      memorizeLastValue: true,
    },
  ],
  items: [
    {
      displayName: 'UPW Regal',
      typeId: ItemType.Shelf,
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.ShelfHeight]: {},
        [ItemProperties.ShelfWidth]: {},
        [ItemProperties.ShelfDepth]: {},
        [ItemProperties.DefaultTrayCount]: {},
        [ItemProperties.HasShelfBackWall]: {},
        [ItemProperties.UseStandardLengths]: {},
        [ItemProperties.GridConstruction]: {},
        [ItemProperties.FixColumnWidths]: {},
        [ItemProperties.FixColumnHeights]: {},
        [ItemProperties.WithPlinthPanel]: {},
      },
    },
    {
      displayName: 'Column',
      typeId: ItemType.ColumnDropBox,
      properties: {
        [ItemProperties.ShelfDepth]: {},
        [ItemProperties.DefaultTrayCount]: {},
        [ItemProperties.ColumnHeight]: {},
        [ItemProperties.ColumnIndex]: {},
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.HasShelfBackWall]: {},
        [ItemProperties.WithPlinthPanel]: {},
      },
    },
    {
      displayName: 'Seitenwand',
      typeId: ItemType.SideWall,
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.SideWallType]: {},
        [ItemProperties.SideWallHeight]: {},
        [ItemProperties.ShelfDepth]: {},
      },
    },
    {
      displayName: 'Traverse',
      typeId: ItemType.Traverse,
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.ShelfDepth]: {},
      },
    },
    {
      displayName: 'Regalrückwand',
      typeId: ItemType.ShelfBackWall,
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.ShelfBackWallHeight]: {},
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.ShelfDepth]: {},
      },
    },
    {
      displayName: 'Türe',
      typeId: ItemType.Door,
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.DoorHinge]: {},
        [ItemProperties.DoorHeight]: {},
        [ItemProperties.DoorWidth]: {},
      },
    },
    {
      displayName: 'Türe',
      typeId: ItemType.DoorSet,
      icon: require('../assets/interior/tuere.png'),
      category: 'draggableDeprecated',
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.ShelfDepth]: {},
        [ItemProperties.DoorHinge]: {},
        [ItemProperties.DoorHeight]: {},
      },
    },
    {
      displayName: 'Schublade',
      typeId: ItemType.Drawer,
      icon: require('../assets/interior/schublade.png'),
      category: 'draggableDeprecated',
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.InteriorMaterial]: {},
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.ShelfDepth]: {},
        [ItemProperties.DrawerHeight]: {},
      },
    },
    {
      displayName: 'Rollschublade',
      typeId: ItemType.RollingDrawer,
      icon: require('../assets/interior/rollschublade.png'),
      category: 'draggableDeprecated',
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.InteriorMaterial]: {},
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.ShelfDepth]: {},
      },
    },
    {
      displayName: 'Klappe',
      typeId: ItemType.Flap,
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.ColumnWidth]: {},
      },
    },
    {
      displayName: 'Klappe',
      typeId: ItemType.FlapSet,
      icon: require('../assets/interior/klappe.png'),
      category: 'draggableDeprecated',
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.ShelfDepth]: {},
      },
    },
    {
      displayName: 'Rückwand',
      typeId: ItemType.InteriorBackWall,
      icon: require('../assets/interior/rueckwand.png'),
      category: 'draggableDeprecated',
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.ShelfDepth]: {},
        [ItemProperties.InteriorBackWallHeight]: {},
      },
    },
    {
      displayName: 'Tablar',
      typeId: ItemType.Tray,
      icon: require('../assets/interior/tablar.png'),
      category: 'draggable',
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.ShelfDepth]: {},
        [ItemProperties.IsFixTray]: {},
      },
    },
    {
      displayName: 'InnerMeasurement',
      typeId: ItemType.InnerMeasurement,
      properties: {
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.ShelfDepth]: {},
        [ItemProperties.InnerMeasurementHeight]: {},
      },
    },
    {
      displayName: 'PlinthPanel',
      typeId: ItemType.PlinthPanel,
      properties: {
        [ItemProperties.ShelfMaterial]: {},
        [ItemProperties.ColumnWidth]: {},
        [ItemProperties.ShelfDepth]: {},
      },
    },
  ],
}
