import { Typography } from '@mui/material'
import { useEffect } from 'react'

export interface MailTestModeIndicatorProps {
  envVariable: string | undefined
}

export const MailTestModeIndicator = (props: MailTestModeIndicatorProps) => {
  const { envVariable } = props

  useEffect(() => {
    if (envVariable && envVariable.toLowerCase() === 'true') {
      ;(window as any).mailTestMode = true
    }
  }, [envVariable])

  if (!(window as any).mailTestMode) {
    return null
  }

  return (
    <Typography
      style={{
        position: 'absolute',
        left: '256px',
        top: '32px',
        color: 'red',
        pointerEvents: 'none',
        zIndex: '999',
      }}
    >
      Mail Test Mode: Es werden nur Mails an die eingegebene E-Mailadresse versendet!
    </Typography>
  )
}

export const useMailTestMode = () => {
  return ((window as any).mailTestMode as boolean) || false
}
