import { Button } from '@mui/material'
import { ColumnGridConstruction, Item, useColumnGridProperties, useItemProperties, useSetCameraView } from '@teamsesam/configurator-core'
import { ItemProperties } from '../three/config'
import AddIcon from '@mui/icons-material/Add'

export interface AddColumnButtonProps {
  item: Item
}

export const AddColumnButton = (props: AddColumnButtonProps) => {
  const { item } = props
  const { addColumnToTheRight } = useColumnGridProperties()
  const { fixColumnWidths } = useItemProperties(item, [ItemProperties.FixColumnWidths])
  const setCameraView = useSetCameraView()

  const addColumn = () => {
    const columns = (item.getPropertyValue(ItemProperties.GridConstruction) as ColumnGridConstruction).columns
    const lastColumn = columns[columns.length - 1]
    addColumnToTheRight({ ...lastColumn })
    setCameraView('default')
  }

  if (!fixColumnWidths) {
    return null
  }

  return (
    <Button startIcon={<AddIcon />} onClick={addColumn} title="Spalte hinzufügen" sx={{ pointerEvents: 'auto' }}>
      Spalte hinzufügen
    </Button>
  )
}
