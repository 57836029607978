import {
  AttachedItem,
  BevelEdge,
  createBoardGeometry,
  Drawer,
  DrawerOptions,
  InteractionBehavior,
  Item,
  ItemContext,
} from '@teamsesam/configurator-core'
import { ItemProperties, ItemType } from '../config'
import * as THREE from 'three'
import {
  frontClearance,
  frontOffsetY,
  frontPlateThickness,
  getDrawerDepth,
  getHandleMesh,
  handleDepth,
  handleOffset,
  plateThickness,
} from '../constants'
import { addTraysOnTopAndBottomOfDrawers, updateBackWalls, updateDropBoxByDraggableItemResize } from './columnDropBox'

export const drawer = (context: ItemContext) => {
  const { item, on, materials, setInteractionBehavior, createItem } = context
  let currentMaterials = materials

  let drawerFront: THREE.Mesh | undefined = undefined
  const handle = getHandleMesh('horizontal')
  handle.position.z = frontPlateThickness / 2
  const drawerFrontWithHandle = new THREE.Object3D()
  drawerFrontWithHandle.add(handle)
  let drawer: Drawer

  const getDrawerHeightOptions = (drawerHeight: number) => {
    const frontHeight = drawerHeight
    const drawerBoxHeight = drawerHeight - plateThickness
    return {
      boxHeight: drawerBoxHeight,
      height: frontHeight,
    }
  }

  const getDrawerWidthAndDepthOptions = () => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth)
    const drawerDepth = getDrawerDepth(item.getPropertyValue(ItemProperties.ShelfDepth))
    const drawerHeight = item.getPropertyValue(ItemProperties.DrawerHeight)
    const frontHeight = drawerHeight
    const drawerBoxHeight = drawerHeight - plateThickness

    return {
      boxWidth: columnWidth - plateThickness,
      boxDepth: 0,
      width: columnWidth - frontClearance,
      depth: drawerDepth + handleDepth,
      boxHeight: drawerBoxHeight,
      height: frontHeight,
    }
  }

  const createDrawer = () => {
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth)
    const drawerHeight = item.getPropertyValue(ItemProperties.DrawerHeight)

    const drawerOptions: DrawerOptions = {
      ...getDrawerHeightOptions(drawerHeight),
      ...getDrawerWidthAndDepthOptions(),
      plateThickness: frontPlateThickness,
      boxMaterial: currentMaterials.interiorMaterial,
    }

    drawerFront = new THREE.Mesh(
      createBoardGeometry(new THREE.Vector3(drawerOptions.width, drawerOptions.height + frontOffsetY, frontPlateThickness), {
        bevelEdge: BevelEdge.all,
        bevelWidth: 0.1,
      }),
      currentMaterials.shelfMaterial,
    )
    drawerFront.position.z = handleDepth / -2
    drawerFrontWithHandle.add(drawerFront)

    handle.position.y = (drawerHeight - handleOffset) / 2
    drawer = new Drawer(drawerFrontWithHandle, drawerOptions)
    drawer.position.z = shelfDepth + frontPlateThickness + handleDepth - drawerOptions.depth
    item.add(drawer)

    item.updateBoundingBox()
  }

  const updateDrawer = () => {
    if (drawerFront) {
      drawerFrontWithHandle.remove(drawerFront)
    }
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth)
    const drawerHeight = item.getPropertyValue(ItemProperties.DrawerHeight)

    const drawerOptions = getDrawerWidthAndDepthOptions()
    drawer.update(drawerOptions)
    drawer.position.z = shelfDepth + frontPlateThickness + handleDepth - drawerOptions.depth

    drawerFront = new THREE.Mesh(
      createBoardGeometry(new THREE.Vector3(drawerOptions.width, drawerOptions.height + frontOffsetY, frontPlateThickness), {
        bevelEdge: BevelEdge.all,
        bevelWidth: 0.1,
      }),
      currentMaterials.shelfMaterial,
    )
    drawerFront.position.z = handleDepth / -2
    drawerFrontWithHandle.add(drawerFront)

    handle.position.y = (drawerHeight - handleOffset) / 2

    item.updateBoundingBox()
  }

  createDrawer()

  on.propertyChanged([ItemProperties.DrawerHeight, ItemProperties.ColumnWidth, ItemProperties.ShelfDepth], updateDrawer)
  on.propertyChanged([ItemProperties.DrawerHeight], () => {
    updateDropBoxByDraggableItemResize(item)
    updateBackWalls(item.parentItem, createItem)
    addTraysOnTopAndBottomOfDrawers(item.parentItem, createItem)
  })

  let dropBoxOnStartDragging: Item | undefined = undefined
  on.dropped(() => {
    dropBoxOnStartDragging = undefined
  })
  on.dropBoxLeaved((dropBox) => {
    if (!dropBoxOnStartDragging) {
      dropBoxOnStartDragging = dropBox
    }
  })
  on.startDragging(() => {
    if (!dropBoxOnStartDragging) {
      dropBoxOnStartDragging = item.parentItem
    }
    if (dropBoxOnStartDragging) {
      const trays = dropBoxOnStartDragging.getItems(ItemType.Tray)
      const existingTopTray = trays.find((tray) => Math.abs(tray.position.y - item.getMax().y) < 2) as AttachedItem
      if (existingTopTray && (!existingTopTray.attachedBy || existingTopTray.attachedBy.length === 0)) {
        dropBoxOnStartDragging.remove(existingTopTray)
      }
      const existingBottomTray = trays.find((tray) => Math.abs(tray.position.y - item.getMin().y) < 2) as AttachedItem
      if (existingBottomTray && (!existingBottomTray.attachedBy || existingBottomTray.attachedBy.length === 0)) {
        dropBoxOnStartDragging.remove(existingBottomTray)
      }
    }
  })

  on.materialsChanged((materials) => {
    currentMaterials = materials
    if (drawerFront) {
      drawerFront.material = materials.shelfMaterial
    }
  })

  on.selected(() => {
    drawer.animateOut()
    return () => drawer.animateIn()
  })

  on.validateDropBox((dropBox) => {
    if (dropBox.size.z === 22.5) {
      return 'Schubladen nicht für Tiefe 22.5cm'
    }
    if (![63, 54, 45].includes(dropBox.size.x)) {
      return 'Schubladen nur für Spaltenbreite 43, 52 und 61cm'
    }
    return undefined
  })

  setInteractionBehavior(InteractionBehavior.SelectableAndDraggable)
}
