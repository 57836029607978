import React from 'react'
import { Typography } from '@mui/material'
import { useItemProperties } from '@teamsesam/configurator-core'
import { ItemProperties } from '../../three/config'
import { LabelContainer } from '../LabelContainer'
import { drawerHeightFlat, drawerHeightHigh, getDrawerWidth } from '../../three/constants'
import { ItemOptionsProps } from './InteriorProperties'
import { LabelSelect } from '@teamsesam/core'

export function DrawerOptions(props: ItemOptionsProps) {
  const { item } = props
  const { drawerHeight, columnWidth } = useItemProperties(item, [ItemProperties.DrawerHeight, ItemProperties.ColumnWidth])
  return (
    <>
      <LabelContainer label="Höhe">
        <LabelSelect
          value={drawerHeight}
          onChange={(e) => item.setProperty(ItemProperties.DrawerHeight, e.target.value)}
          options={[
            { value: drawerHeightFlat, label: `Flach (${drawerHeightFlat} cm)` },
            { value: drawerHeightHigh, label: `Hoch (${drawerHeightHigh} cm)` },
          ]}
        />
      </LabelContainer>
      <LabelContainer label="Breite">
        <Typography>{getDrawerWidth(columnWidth)} cm</Typography>
      </LabelContainer>
    </>
  )
}
