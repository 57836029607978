import * as THREE from 'three'
import { behavior, Configurator } from '@teamsesam/configurator-core'

export const addLights: behavior = ({ getInstance }) => {
  const configurator = getInstance(Configurator)

  configurator.addObject((scene) => {
    const light = new THREE.AmbientLight(0xffffff, 1.1)
    return light
  })

  configurator.addObject((scene) => {
    const light = new THREE.DirectionalLight(0xffffff, 0.4)
    light.position.copy(new THREE.Vector3(500, 500, 500))
    return light
  })

  configurator.addObject((scene) => {
    const light = new THREE.DirectionalLight(0xffffff, 0.1)
    light.position.copy(new THREE.Vector3(-100, 10, 35))
    return light
  })
}
