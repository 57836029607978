import React from 'react'
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import { CoreDialog, Form, FormCheckbox, FormNumberField, FormSelect, FormTextField, useShowMessageBox } from '@teamsesam/core'
import * as yup from 'yup'
import { useLoadingScreen } from '@teamsesam/configurator-core'
import { useCreateOrder } from '../hooks/useCreateOrder'

export interface OrderDialogProps {
  closeDialog: () => void
}

const customerTypeValues = ['Privatkunde', 'Vertriebspartner', 'Architekt/Designer', 'Firma'] as const
export type CustomerType = (typeof customerTypeValues)[number]

export interface OrderFormData {
  orderNr?: string
  customerType: CustomerType
  company?: string
  name: string
  deliveryStreet: string
  deliveryZipCode: number
  deliveryCity: string
  billingAddress?: string
  email: string
  phone: string
  remark?: string
  subscribeToNewsletter: boolean
}

const schema: yup.ObjectSchema<OrderFormData> = yup.object({
  orderNr: yup.string(),
  customerType: yup.mixed<CustomerType>().oneOf(customerTypeValues).required('Pflichtfeld'),
  company: yup.string(),
  name: yup.string().required('Pflichtfeld'),
  deliveryStreet: yup.string().required('Pflichtfeld'),
  deliveryZipCode: yup.number().required('Pflichtfeld'),
  deliveryCity: yup.string().required('Pflichtfeld'),
  billingAddress: yup.string(),
  email: yup.string().required('Pflichtfeld').email('Keine gültige E-Mailadresse'),
  phone: yup.string().required('Pflichtfeld'),
  remark: yup.string(),
  subscribeToNewsletter: yup.boolean().required(),
})

export const OrderDialog = (props: OrderDialogProps) => {
  const { closeDialog } = props
  const createOrder = useCreateOrder()
  const { showMessageBox } = useShowMessageBox()
  const { showLoadingScreen, hideLoadingScreen } = useLoadingScreen()

  const onSubmit = async (data: OrderFormData) => {
    try {
      showLoadingScreen('Bestellung wird vorbereitet...')
      await createOrder(data)
      closeDialog()
      showMessageBox('Bestellung versendet', 'Ein E-Mail mit allen Informationen zur Bestellung wurde versendet.')
    } catch (error) {
      showMessageBox('Unexpected Error', error && typeof error === 'object' ? error.toString() : (error as string))
    } finally {
      hideLoadingScreen()
    }
  }

  return (
    <CoreDialog showCloseButton open={true} onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Bestellung</DialogTitle>
      <DialogContent>
        <Form<OrderFormData> onSubmit={onSubmit} validationSchema={schema} id="save-design-form">
          <Stack spacing={1}>
            <FormTextField label="Bestell-Nr. / Kommission" propertyPath="orderNr" autoFocus fullWidth />
            <FormSelect label="Ich bin" propertyPath="customerType" options={customerTypeValues} fullWidth />
            <FormTextField label="Firma" propertyPath="company" autoComplete="on" fullWidth />
            <FormTextField label="Name" propertyPath="name" autoComplete="on" fullWidth required />
            <FormTextField label="Lieferadresse" propertyPath="deliveryStreet" autoComplete="on" fullWidth required />
            <Stack direction="row" spacing={2}>
              <FormNumberField
                integer
                noThousandSeparator
                label="PLZ"
                propertyPath="deliveryZipCode"
                autoComplete="on"
                fullWidth
                required
                style={{ width: 100 }}
              />
              <FormTextField label="Ort" propertyPath="deliveryCity" autoComplete="on" fullWidth required />
            </Stack>
            <FormTextField label="Alternative Rechnungsadresse" propertyPath="billingAddress" autoComplete="on" fullWidth />
            <FormTextField label="E-Mail" propertyPath="email" autoComplete="on" fullWidth required />
            <FormTextField label="Telefon" propertyPath="phone" autoComplete="on" fullWidth required />
            <FormTextField label="Bemerkungen" propertyPath="remark" autoComplete="on" fullWidth rows={3} multiline />
            <FormCheckbox label="Ja, ich möchte den Newsletter abonnieren" propertyPath="subscribeToNewsletter" />
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()}>Abbrechen</Button>
        <Button variant="contained" color="primary" type="submit" form="save-design-form">
          Bestellen
        </Button>
      </DialogActions>
    </CoreDialog>
  )
}
