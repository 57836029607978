import React from 'react'
import { createTheme, IconButton, ThemeProvider, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Horizontal, LabelCheckbox, Vertical } from '@teamsesam/core'
import { PropertySlider } from './PropertySlider'
import { LabelContainer } from './LabelContainer'
import {
  calculateShelfWidths,
  maxTrayCount,
  minShelfHeight,
  shelfDepths,
  sideHeights,
  minShelfWidth,
  maxShelfHeight,
  plateThickness,
} from '../three/constants'
import {
  useColumnGridProperties,
  useDelayedAction,
  useItemProperties,
  useRootItem,
  useSetCameraView,
  useSetPropertyToAll,
} from '@teamsesam/configurator-core'
import { ItemProperties } from '../three/config'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#000000',
    },
  },
  typography: {
    fontFamily: 'OpenSans',
    fontSize: 12,
    body1: {
      fontWeight: 300,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: 'textPrimary',
      },
    },
    MuiSlider: {
      defaultProps: {
        size: 'small',
      },
    },
  },
})

const widths = calculateShelfWidths()
const minWidth = widths[0]
const maxWidth = widths[widths.length - 1]
const widthMarks = widths.map((value) => ({ value }))

const heights = sideHeights
const minHeight = heights[0]
const heightMarks = heights.map((value) => ({ value }))

const depths = shelfDepths
const minDepth = depths[0]
const maxDepth = depths[depths.length - 1]
const depthMarks = depths.map((value) => ({ value }))

export function ConstructionProperties() {
  const setPropertyToAll = useSetPropertyToAll()
  const setCameraView = useSetCameraView()
  const shelfItem = useRootItem()
  const setDelayedDefaultCameraView = useDelayedAction(() => setCameraView('default'))
  const { totalWidth, maxColumnHeight } = useColumnGridProperties()

  const {
    shelfHeight,
    shelfWidth,
    shelfDepth,
    defaultTrayCount,
    useStandardLengths,
    fixColumnWidths,
    fixColumnHeights,
  } = useItemProperties(shelfItem, [
    ItemProperties.ShelfHeight,
    ItemProperties.ShelfWidth,
    ItemProperties.ShelfDepth,
    ItemProperties.DefaultTrayCount,
    ItemProperties.UseStandardLengths,
    ItemProperties.FixColumnWidths,
    ItemProperties.FixColumnHeights,
    ItemProperties.GridConstruction,
  ])

  if (!shelfHeight) {
    return null
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Vertical
        style={{
          paddingLeft: '32px',
          paddingRight: '32px',
          paddingTop: '16px',
          paddingBottom: '16px',
          backgroundColor: '#1A1A1A',
          overflow: 'hidden',
        }}
        spacing={1}
      >
        <Vertical spacing={3}>
          <Horizontal style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography style={{ fontWeight: 500 }}>REGALAUFBAU</Typography>
            <LabelCheckbox
              label="Standardmasse*"
              size="small"
              checked={useStandardLengths}
              onChange={(e, checked) => setPropertyToAll(ItemProperties.UseStandardLengths, checked)}
              sx={{ color: '#e9e9e9 !important' }}
              labelProps={{ sx: { marginRight: '-20px', '& .MuiFormControlLabel-label': { color: '#e9e9e9', fontSize: '12px' } } }}
            />
          </Horizontal>
          <PropertySlider
            label="Höhe"
            value={shelfHeight}
            min={useStandardLengths ? minHeight : minShelfHeight}
            max={maxShelfHeight}
            marks={heightMarks}
            step={useStandardLengths ? null : 1}
            onChange={
              ((e: any, value: number) => {
                setPropertyToAll(ItemProperties.ShelfHeight, value)
                setDelayedDefaultCameraView()
              }) as any
            }
            sliderReplacement={
              fixColumnHeights ? (
                <Horizontal spacing={2}>
                  <Typography>{maxColumnHeight} cm</Typography>
                  <LabelCheckbox
                    label="Fixe Spaltenhöhen"
                    checked={true}
                    size="small"
                    onChange={() => {
                      shelfItem.setProperties({
                        [ItemProperties.ShelfHeight]: maxColumnHeight,
                        [ItemProperties.FixColumnHeights]: false,
                      })
                    }}
                  />
                </Horizontal>
              ) : undefined
            }
          />
          <PropertySlider
            label="Breite"
            value={shelfWidth}
            min={useStandardLengths ? minWidth : minShelfWidth}
            max={maxWidth}
            marks={widthMarks}
            step={useStandardLengths ? null : 1}
            onChange={
              ((e: any, value: number) => {
                setPropertyToAll(ItemProperties.ShelfWidth, value)
                setDelayedDefaultCameraView()
              }) as any
            }
            sliderReplacement={
              fixColumnWidths ? (
                <Horizontal spacing={2}>
                  <Typography>{totalWidth + plateThickness} cm</Typography>
                  <LabelCheckbox
                    label="Fixe Spaltenbreiten"
                    checked={true}
                    size="small"
                    onChange={() => {
                      const shelfWidth = totalWidth + plateThickness
                      shelfItem.setProperties({
                        [ItemProperties.ShelfWidth]: shelfWidth > maxWidth ? maxWidth : shelfWidth,
                        [ItemProperties.FixColumnWidths]: false,
                      })
                      setCameraView('default')
                    }}
                  />
                </Horizontal>
              ) : undefined
            }
          />
          <PropertySlider
            label="Tiefe"
            value={shelfDepth}
            min={minDepth}
            max={maxDepth}
            marks={depthMarks}
            step={null}
            onChange={
              ((e: any, value: number) => {
                setPropertyToAll(ItemProperties.ShelfDepth, value)
                setDelayedDefaultCameraView()
              }) as any
            }
          />
          <LabelContainer label="Tablare">
            <Horizontal style={{ alignItems: 'center' }} spacing={2}>
              <IconButton
                disabled={defaultTrayCount <= 2}
                onClick={() => setPropertyToAll(ItemProperties.DefaultTrayCount, defaultTrayCount - 1)}
              >
                <RemoveIcon />
              </IconButton>
              <Typography>{defaultTrayCount}</Typography>
              <IconButton
                disabled={defaultTrayCount >= maxTrayCount}
                onClick={() => setPropertyToAll(ItemProperties.DefaultTrayCount, defaultTrayCount + 1)}
              >
                <AddIcon />
              </IconButton>
            </Horizontal>
          </LabelContainer>
        </Vertical>
      </Vertical>
    </ThemeProvider>
  )
}
