import { InteractionBehavior, ItemContext, attachedItem } from '@teamsesam/configurator-core'
import * as THREE from 'three'
import { columnWidths, flapHeight, frontPlateThickness } from '../constants'
import { ItemProperties, ItemType } from '../config'

export const flapSet = (context: ItemContext) => {
  const { item, on, setInteractionBehavior, createItem } = context

  attachedItem(context, {
    typeId: 'Tray',
    itemOptions: { properties: { [ItemProperties.IsFixTray]: true } },
    getPosition: () => new THREE.Vector3(0, item.position.y - flapHeight / 2 - 0.5, 0),
  })

  const flap = createItem(ItemType.Flap)
  item.add(flap)

  const updateFlapSet = () => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth) as number
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth) as number

    flap.setProperty(ItemProperties.ColumnWidth, columnWidth)
    flap.position.z = (shelfDepth + frontPlateThickness) / 2

    item.updateBoundingBox()
  }

  updateFlapSet()

  on.propertyChanged([ItemProperties.ColumnWidth, ItemProperties.ShelfDepth], updateFlapSet)

  on.selected(() => {
    flap.itemAnimations.startAnimation('open')
    return () => flap.itemAnimations.reverseAnimation('open')
  })

  on.validateDropBox((dropBox) => {
    if (!columnWidths.includes(dropBox.size.x)) {
      return 'Klappen nur für Standardmasse'
    }
    return undefined
  })

  setInteractionBehavior(InteractionBehavior.SelectableAndDraggable)
}
