import { createTheme } from '@mui/material'
import OpenSansRegular from './assets/fonts/OpenSans-Regular.ttf'
import OpenSansLight from './assets/fonts/OpenSans-Light.ttf'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2C7768',
    },
  },
  typography: {
    fontFamily: 'OpenSans',
    fontSize: 12,
    body1: {
      color: '#262626',
    },
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        size: 'small',
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'OpenSans';
          font-weight: 400;
          src: local('OpenSans'), local('OpenSans-Regular'), url(${OpenSansRegular}) format('truetype');
        }
        @font-face {
          font-family: 'OpenSans';
          font-weight: 300;
          src: local('OpenSans'), local('OpenSans-Regular'), url(${OpenSansLight}) format('truetype');
        }
        html {
          height: 100%;
        }
        body {
          height: 100%;
          background-color: #ffffff;
        }
        a {
          color: #bababa;
          text-decoration: none;
          font-size: 12px;
          &:hover {
            color: #bababa;
            text-decoration: underline;
          }
        }
        .border-layout-application__right-drawer {
          box-shadow: -5px 0px 25px 0px rgba(0, 0, 0, 0.1);
        }
        .dragging-img {
          cursor: move;
          height: 53px;
          box-sizing: border-box;
          padding: 10%;
          transition: all 150ms ease-in;
          filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));
          &:hover {
            padding: 0;
            filter: drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.16));
          }
        }

        .measurement-overlay__scale-line {
          stroke: #000000;
          stroke-width: 1;
        }

        .measurement-overlay__label {
          font-size: 12px;
          width: 80px;
        }

        .measurement-overlay__label--vertical {
          height: 80px;
        }
        `,
    },
  },
})
