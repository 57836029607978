import * as THREE from 'three'
import { ShadowMesh } from '../shadowMesh'

export class WallRightShadow extends ShadowMesh {
  protected getShadowSize(itemSize: THREE.Vector3): THREE.Vector2 {
    return new THREE.Vector2(itemSize.z, itemSize.y)
  }

  protected setPosition(size: THREE.Vector3, shadowSize: THREE.Vector2): void {
    this.position.x = (size.x + shadowSize.x) / 2
    this.position.z = size.z / -2
  }
}
