import {
  ColumnConstruction,
  columnGrid,
  ColumnGridConstruction,
  ColumnGridItem,
  customBoundingBoxBySize,
  Item,
  ItemContext,
  MeasurementOverlay,
  OverlayAnchorX,
  OverlayAnchorY,
  OverlayAnchorZ,
  SideWallConstruction,
} from '@teamsesam/configurator-core'
import { ItemProperties, ItemType, SideWallType } from '../config'
import { calculateColumnWidths } from '../calculateColumnWidths'
import { plateThickness } from '../constants'
import { defaultOuterShadowOptions, OuterShadow } from '../shadows/outer/outerShadow'
import * as THREE from 'three'
import _ from 'lodash'
import { AddColumnButton } from '../../components/AddColumnButton'
import { Views } from '../../hooks/useSetView'

const getSideWallType = (construction: SideWallConstruction) => {
  if (construction.isMostLeft) {
    return SideWallType.Left
  }
  return construction.isMostRight ? SideWallType.Right : SideWallType.Middle
}

export const shelf = (context: ItemContext) => {
  const { item, on, createItem } = context

  const gridItem = item as ColumnGridItem
  const { intensity, floorLeft, floorRight, floorCenter } = defaultOuterShadowOptions
  const outerShadow = new OuterShadow({ intensity, floorLeft, floorRight, floorCenter })
  item.add(outerShadow)

  item.customBoundingBox = customBoundingBoxBySize(
    () => gridItem.totalWidth + plateThickness,
    () => gridItem.maxColumnHeight,
    () => item.getPropertyValue(ItemProperties.ShelfDepth),
  )

  const createSideWall = (construction: SideWallConstruction) => {
    return createItem(ItemType.SideWall, {
      properties: {
        [ItemProperties.SideWallHeight]: construction.height,
        [ItemProperties.SideWallType]: getSideWallType(construction),
      },
    })
  }

  const updateSideWall = (sideWall: Item, construction: SideWallConstruction) => {
    sideWall.setProperties({
      [ItemProperties.SideWallHeight]: construction.height,
      [ItemProperties.SideWallType]: getSideWallType(construction),
    })
  }

  const createColumn = (construction: ColumnConstruction) => {
    return createItem(ItemType.ColumnDropBox, {
      properties: {
        [ItemProperties.ColumnWidth]: construction.width,
        [ItemProperties.ColumnHeight]: construction.height,
        [ItemProperties.ColumnIndex]: construction.index,
      },
    })
  }

  const updateColumn = (column: Item, construction: ColumnConstruction) => {
    column.setProperties({
      [ItemProperties.ColumnWidth]: construction.width,
      [ItemProperties.ColumnHeight]: construction.height,
      [ItemProperties.ColumnIndex]: construction.index,
    })
  }

  columnGrid({
    constructionProperty: ItemProperties.GridConstruction,
    createSideWall,
    updateSideWall,
    createColumn,
    updateColumn,
    ...context,
  })

  const updateShadow = () => {
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth) as number
    outerShadow.setSize(new THREE.Vector3(gridItem.totalWidth + plateThickness, gridItem.maxColumnHeight, shelfDepth))
  }

  updateShadow()

  const updateShelfByWidth = () => {
    const shelfWidth = item.getPropertyValue(ItemProperties.ShelfWidth)
    const oldConstruction = item.getPropertyValue(ItemProperties.GridConstruction) as ColumnGridConstruction
    const columnWidths: number[] = calculateColumnWidths(shelfWidth)
    const construction: ColumnGridConstruction = {
      width: shelfWidth - plateThickness,
      columns: columnWidths.map((width, index) => ({
        width,
        height:
          oldConstruction.columns.length > index
            ? oldConstruction.columns[index].height
            : (_.last(oldConstruction.columns)?.height as number),
      })),
    }
    item.setProperty(ItemProperties.GridConstruction, construction)
  }

  const updateShelfByHeight = () => {
    const shelfHeight = item.getPropertyValue(ItemProperties.ShelfHeight)
    const oldConstruction = item.getPropertyValue(ItemProperties.GridConstruction) as ColumnGridConstruction
    const construction: ColumnGridConstruction = {
      width: oldConstruction.width,
      columns: oldConstruction.columns.map((column) => ({ width: column.width, height: shelfHeight })),
    }
    item.setProperty(ItemProperties.GridConstruction, construction)
  }

  on.propertyChanged([ItemProperties.GridConstruction], () => item.updateBoundingBox())

  on.propertyChanged([ItemProperties.GridConstruction], updateShadow)
  on.propertyChanged([ItemProperties.ShelfDepth], updateShadow)

  on.propertyChanged([ItemProperties.ShelfWidth], updateShelfByWidth)
  on.propertyChanged([ItemProperties.FixColumnWidths], (value) => {
    if (!value) {
      updateShelfByWidth()
    }
  })
  on.propertyChanged([ItemProperties.ShelfHeight], updateShelfByHeight)
  on.propertyChanged([ItemProperties.FixColumnHeights], (value) => {
    if (!value) {
      updateShelfByHeight()
    }
  })

  item.overlays.addOverlay({
    component: MeasurementOverlay,
    props: { item, getText: () => `${gridItem.totalWidth + plateThickness} cm` },
    anchors: { x: OverlayAnchorX.center, y: OverlayAnchorY.top, z: OverlayAnchorZ.center },
    offset2D: { x: 0, y: -16 },
    visibleForViews: [Views['2D']],
  })

  item.overlays.addOverlay({
    component: MeasurementOverlay,
    props: { item, getText: () => `${gridItem.maxColumnHeight} cm`, isVertical: true },
    anchors: { x: OverlayAnchorX.left, y: OverlayAnchorY.center, z: OverlayAnchorZ.center },
    offset2D: { x: -16, y: 0 },
    visibleForViews: [Views['2D']],
  })

  item.overlays.addOverlay({
    component: AddColumnButton,
    props: { item },
    anchors: { x: OverlayAnchorX.right, y: OverlayAnchorY.center, z: OverlayAnchorZ.center },
    offset2D: { x: 32, y: 0 },
  })
}
