import { SideWallType } from './config'

export const getSideWallArticleNr = (height: number, depth: number, type: SideWallType) => {
  if (type === SideWallType.Middle) {
    if (depth === 22.5) {
      switch (height) {
        case 54:
          return '600009'
        case 72:
          return '602309'
        case 99:
          return '602310'
        case 117:
          return '602311'
        case 162:
          return '602312'
        case 207:
          return '602313'
        case 234:
          return '602314'
        case 261:
          return '602315'
      }
    }
    if (depth === 36) {
      switch (height) {
        case 54:
          return '602316'
        case 72:
          return '602318'
        case 99:
          return '602319'
        case 117:
          return '602321'
        case 162:
          return '602323'
        case 207:
          return '602324'
        case 234:
          return '602326'
        case 261:
          return '602328'
      }
    }
    switch (height) {
      case 54:
        return '602330'
      case 72:
        return '602331'
      case 99:
        return '604203'
      case 117:
        return '604204'
      case 162:
        return '602332'
      case 207:
        return '602333'
      case 234:
        return '602334'
      case 261:
        return '602335'
    }
  }
  if (depth === 22.5) {
    switch (height) {
      case 54:
        return '602474'
      case 72:
        return '602475'
      case 99:
        return '602476'
      case 117:
        return '602477'
      case 162:
        return '602478'
      case 207:
        return '602479'
      case 234:
        return '602480'
      case 261:
        return '602481'
    }
  }
  if (depth === 36) {
    switch (height) {
      case 54:
        return '602482'
      case 72:
        return '602483'
      case 99:
        return '602484'
      case 117:
        return '602485'
      case 162:
        return '602486'
      case 207:
        return '602487'
      case 234:
        return '602488'
      case 261:
        return '602489'
    }
  }
  switch (height) {
    case 54:
      return '602490'
    case 72:
      return '602491'
    case 99:
      return '602492'
    case 117:
      return '602493'
    case 162:
      return '602495'
    case 207:
      return '602496'
    case 234:
      return '602497'
    case 261:
      return '602498'
  }
  throw new Error('no article-nr found')
}

export const getTraverseArticleNr = (width: number) => {
  switch (width) {
    case 43.0:
      return '602360'
    case 52.0:
      return '602361'
    case 61.0:
      return '602362'
    case 88.0:
      return '602363'
  }
  throw new Error('no article-nr found')
}

export const getDrawerArticleNr = (width: number, height: number, depth: number) => {
  if (height === 8) {
    if (depth === 34) {
      switch (width) {
        case 44:
          return '265089'
        case 53:
          return '264742'
        case 62:
          return '265109'
      }
    }
    switch (width) {
      case 44:
        return '265113'
      case 53:
        return '265116'
      case 62:
        return '265104'
    }
  }
  if (depth === 34) {
    switch (width) {
      case 44:
        return '265097'
      case 53:
        return '264743'
      case 62:
        return '265101'
    }
  }
  switch (width) {
    case 44:
      return '265121'
    case 53:
      return '265124'
    case 62:
      return '265090'
  }
  throw new Error('no article-nr found')
}

export const getRollingDrawerArticleNr = (width: number, depth: number) => {
  if (depth === 30) {
    switch (width) {
      case 42:
        return '602402'
      case 51:
        return '602404'
      case 60:
        return '602406'
      case 87:
        return '602407'
    }
  }
  switch (width) {
    case 42:
      return '602401'
    case 51:
      return '602400'
    case 60:
      return '602398'
    case 87:
      return '602397'
  }
  throw new Error('no article-nr found')
}

export const getDoorArticleNr = (width: number, height: number) => {
  if (height === 44) {
    switch (width) {
      case 44:
        return '622779'
      case 53:
        return '622780'
      case 62:
        return '622782'
      case 89:
        return '622804'
    }
  }
  if (height === 53) {
    switch (width) {
      case 44:
        return '622783'
      case 53:
        return '622784'
      case 62:
        return '622785'
      case 89:
        return '622868'
    }
  }
  if (height === 62) {
    switch (width) {
      case 44:
        return '622786'
      case 53:
        return '622787'
      case 62:
        return '622788'
      case 89:
        return '622869'
    }
  }
  switch (width) {
    case 44:
      return '622789'
    case 53:
      return '622790'
    case 62:
      return '622791'
    case 89:
      return '622870'
  }
  throw new Error('no article-nr found')
}

export const getFlapArticleNr = (width: number) => {
  switch (width) {
    case 44:
      return '622805'
    case 53:
      return '622806'
    case 62:
      return '622807'
    case 89:
      return '622809'
  }
  throw new Error('no article-nr found')
}

export const getInteriorBackWallArticleNr = (width: number, height: number) => {
  if (height === 45) {
    switch (width) {
      case 45:
        return '613653'
      case 54:
        return '613654'
      case 63:
        return '613655'
      case 90:
        return '613656'
    }
  }
  if (height === 54) {
    switch (width) {
      case 54:
        return '613657'
      case 63:
        return '613658'
      case 90:
        return '613659'
    }
  }
  if (height === 63) {
    switch (width) {
      case 63:
        return '613660'
      case 90:
        return '613661'
    }
  }
  switch (width) {
    case 90:
      return '613662'
  }
  throw new Error('no article-nr found')
}

export const getTrayArticleNr = (width: number, depth: number, isFix: boolean) => {
  if (isFix) {
    if (depth === 22.5) {
      switch (width) {
        case 43:
          return '602336'
        case 52:
          return '602337'
        case 61:
          return '602338'
        case 88:
          return '602339'
      }
    }
    if (depth === 36) {
      switch (width) {
        case 43:
          return '602340'
        case 52:
          return '602341'
        case 61:
          return '602342'
        case 88:
          return '602343'
      }
    }
    switch (width) {
      case 43:
        return '602344'
      case 52:
        return '602345'
      case 61:
        return '602346'
      case 88:
        return '602347'
    }
  }
  if (depth === 20.5) {
    switch (width) {
      case 43:
        return '602348'
      case 52:
        return '602349'
      case 61:
        return '602350'
      case 88:
        return '602351'
    }
  }
  if (depth === 34) {
    switch (width) {
      case 43:
        return '602352'
      case 52:
        return '602353'
      case 61:
        return '602354'
      case 88:
        return '602355'
    }
  }
  switch (width) {
    case 43:
      return '602356'
    case 52:
      return '602357'
    case 61:
      return '602358'
    case 88:
      return '602359'
  }
  throw new Error('no article-nr found')
}

export const getPlinthPanelArticleNr = (width: number) => {
  switch (width) {
    case 43:
      return '602372'
    case 52:
      return '602373'
    case 61:
      return '602370'
    case 88:
      return '602371'
  }
  throw new Error('no article-nr found')
}
