import { ItemContext, RotationAnimation, RotationHinge, createBoardGeometry, BevelEdge } from '@teamsesam/configurator-core'
import * as THREE from 'three'
import { ItemProperties } from '../config'
import { flapHeight, frontOffsetY, frontPlateThickness, getFlapWidth, getHandleMesh, handleOffset } from '../constants'

export const flap = (context: ItemContext) => {
  const { item, on, materials } = context
  let currentMaterials = materials

  let flap: THREE.Mesh | undefined = undefined
  const handle = getHandleMesh('horizontal')
  handle.position.y = (flapHeight - handleOffset) / 2
  const flapWithHandle = new THREE.Object3D()
  flapWithHandle.add(handle)
  item.add(flapWithHandle)

  const updateFlap = () => {
    if (flap) {
      flapWithHandle.remove(flap)
    }
    
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth) as number

    const geometry = createBoardGeometry(new THREE.Vector3(getFlapWidth(columnWidth), flapHeight + frontOffsetY, frontPlateThickness), {
      bevelEdge: BevelEdge.all,
      bevelWidth: 0.1,
    })
    flap = new THREE.Mesh(geometry, currentMaterials.shelfMaterial)
    flapWithHandle.add(flap)

    item.updateBoundingBox()
  }

  updateFlap()

  on.propertyChanged([ItemProperties.ColumnWidth], updateFlap)

  on.materialsChanged((materials) => {
    currentMaterials = materials
    if (flap) {
      flap.material = materials.shelfMaterial
    }
  })

  item.itemAnimations.addAnimation(
    'open',
    new RotationAnimation(flapWithHandle, { hinge: RotationHinge.Bottom, max: Math.PI / -2, min: Math.PI / -4 }),
  )
}
