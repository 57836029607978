import { NumberField, NumberFieldProps } from '@teamsesam/core'
import React, { useState, useCallback, useEffect } from 'react'

type MinMaxNumberFieldProps = NumberFieldProps & { min: number; max: number }

export const MinMaxNumberField = (props: MinMaxNumberFieldProps) => {
  const { min, max, disabled, onChange: onChangeOuter, value, ...rest } = props
  const [errorText, setErrorText] = useState<string | undefined>(undefined)
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [value, setCurrentValue])

  const onChange = useCallback(
    (newValue: number) => {
      if (newValue < min || newValue > max) {
        setErrorText(`Muss zwischen ${min} und ${max} sein`)
      } else {
        setErrorText(undefined)
        if (onChangeOuter) {
          onChangeOuter(newValue)
        }
      }
      setCurrentValue(newValue)
    },
    [min, max, onChangeOuter],
  )

  if (value === undefined) {
    return null
  }

  return (
    <NumberField
      placeholder="auto"
      variant="outlined"
      size="small"
      onChange={(value) => onChange(value as number)}
      value={currentValue}
      disabled={disabled}
      helperText={disabled ? undefined : errorText}
      error={errorText != null && !disabled}
      noThousandSeparator
      integer
      numberProps={{ suffix: ' cm' }}
      {...rest}
    />
  )
}
