import React from 'react'
import { LabelRadioGroup } from '@teamsesam/core'
import {
  ConfigItemManager,
  LayoutProps,
  useInstance,
  useItemProperties,
  useRootItem,
  useSetPropertyToAll,
} from '@teamsesam/configurator-core'
import { ItemProperties } from '../three/config'
import { Radio, RadioProps, styled } from '@mui/material'

interface ColorIconProps {
  color: string
}

const ColorIcon = styled('span')<ColorIconProps>(({ color }) => ({
  width: '40px',
  height: '40px',
  backgroundColor: color,
  borderRadius: '50%',
  border: '1px solid #7f7f7f',
  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  marginRight: '8px',
}))

const ColorSelectedIcon = styled(ColorIcon)({
  borderWidth: '2px',
})

const ColorRadio = (props: RadioProps) => {
  const { value } = props
  const configItemManager = useInstance(ConfigItemManager)
  const materialGroup = configItemManager.materialGroups[0]
  const material = materialGroup.materials.find((material) => material.name === value)

  if (!material) {
    return null
  }

  return (
    <Radio
      {...props}
      icon={<ColorIcon color={material.color} />}
      checkedIcon={<ColorSelectedIcon color={material.color} />}
      sx={{ color: `${material.color} !important` }}
    />
  )
}

export function Color(props: LayoutProps) {
  const shelfItem = useRootItem()
  const { shelfMaterial } = useItemProperties(shelfItem, [ItemProperties.ShelfMaterial])
  const setPropertyToAll = useSetPropertyToAll()
  const configItemManager = useInstance(ConfigItemManager)

  if (!shelfMaterial) {
    return null
  }

  const materialGroup = configItemManager.materialGroups[0]

  return (
    <LabelRadioGroup
      style={{ top: 0, position: 'absolute', paddingLeft: '32px', paddingTop: '24px' }}
      options={materialGroup.materials.map((material) => ({ label: material.displayName || material.name, value: material.name }))}
      value={shelfMaterial}
      onChange={(e, value) => setPropertyToAll(ItemProperties.ShelfMaterial, value)}
      radioTemplate={ColorRadio}
      labelProps={{ style: { textTransform: 'uppercase' } }}
    />
  )
}
