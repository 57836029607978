import { Button } from '@mui/material'
import { OpenDevToolsButton, LayoutProps } from '@teamsesam/configurator-core'
import { Horizontal } from '@teamsesam/core'
import { SaveDesignDialog } from './SaveDesignDialog'
import { useState } from 'react'
import { OrderDialog } from './OrderDialog'

export function Buttons(props: LayoutProps) {
  const [saveDesignDialogOpen, setSaveDesignDialogOpen] = useState(false)
  const [orderDialogOpen, setOrderDialogOpen] = useState(false)

  return (
    <Horizontal style={{ position: 'absolute', bottom: 0, right: 0, paddingBottom: '32px', paddingRight: '44px' }} spacing={2}>
      <OpenDevToolsButton envVariable={process.env.REACT_APP_DEV_TOOLS} />
      <Button onClick={() => setSaveDesignDialogOpen(true)}>Speichern</Button>
      <Button
        variant="contained"
        onClick={() => setOrderDialogOpen(true)}
        style={{ padding: '6px 32px', fontSize: '25px', fontWeight: 500 }}
      >
        Bestellen
      </Button>
      {saveDesignDialogOpen && <SaveDesignDialog closeDialog={() => setSaveDesignDialogOpen(false)} />}
      {orderDialogOpen && <OrderDialog closeDialog={() => setOrderDialogOpen(false)} />}
    </Horizontal>
  )
}
