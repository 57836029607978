import { InteractionBehavior, ItemContext } from '@teamsesam/configurator-core'
import { ItemProperties } from '../config'
import * as THREE from 'three'
import { getFixTrayDepth, getTrayDepth, getTrayWidth, plateThickness } from '../constants'

export const tray = (context: ItemContext) => {
  const { item, on, materials, setInteractionBehavior } = context

  const tray = new THREE.Mesh(new THREE.BoxGeometry(1, plateThickness, 1), materials.shelfMaterial)
  item.add(tray)

  const updateTray = () => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth) as number
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth) as number
    const isFixTray = item.getPropertyValue(ItemProperties.IsFixTray) as boolean
    const trayDepth = isFixTray ? getFixTrayDepth(shelfDepth) : getTrayDepth(shelfDepth)

    tray.scale.x = getTrayWidth(columnWidth)
    tray.scale.z = trayDepth
    tray.position.z = (shelfDepth - trayDepth) / 2

    item.updateBoundingBox()
  }

  updateTray()

  on.propertyChanged([ItemProperties.ColumnWidth, ItemProperties.ShelfDepth, ItemProperties.IsFixTray], updateTray)

  on.materialsChanged((materials) => (tray.material = materials.shelfMaterial))

  setInteractionBehavior(InteractionBehavior.SelectableAndDraggable)
}
