import React from 'react'
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { CoreDialog, Form, FormTextField, useShowMessageBox } from '@teamsesam/core'
import * as yup from 'yup'
import { useSaveDesign } from '../hooks/useSaveDesign'
import { useLoadingScreen } from '@teamsesam/configurator-core'

export interface SaveDesignDialogProps {
  closeDialog: () => void
}

interface FormData {
  email: string
}

const schema: yup.ObjectSchema<FormData> = yup.object({
  email: yup.string().required('Pflichtfeld').email('Keine gültige E-Mailadresse'),
})

export const SaveDesignDialog = (props: SaveDesignDialogProps) => {
  const { closeDialog } = props
  const saveDesign = useSaveDesign()
  const { showMessageBox } = useShowMessageBox()
  const { showLoadingScreen, hideLoadingScreen } = useLoadingScreen()

  const onSubmit = async (data: FormData) => {
    try {
      showLoadingScreen('E-Mail wird versendet...')
      await saveDesign(data.email)
      closeDialog()
      showMessageBox('E-Mail versendet', 'Ein E-Mail mit einem Link zur Konfiguration wurde versendet.')
    } catch (error) {
      showMessageBox('Unexpected Error', error && typeof error === 'object' ? error.toString() : (error as string))
    } finally {
      hideLoadingScreen()
    }
  }

  return (
    <CoreDialog showCloseButton open={true} onClose={closeDialog}>
      <DialogTitle>Konfiguration speichern</DialogTitle>
      <DialogContent>
        <b>Erhalten Sie Ihre Konfiguration per Mail. </b>
        Wir schicken Ihnen den Link zu Ihrer Konfiguration per E-Mail, sodass Sie Ihre Konfiguration jederzeit weiterbearbeiten können.
        <Form<FormData> onSubmit={onSubmit} validationSchema={schema} id="save-design-form">
          <FormTextField label="E-Mail" propertyPath="email" autoComplete="on" autoFocus fullWidth required sx={{ mt: 3 }} />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()}>Abbrechen</Button>
        <Button variant="contained" color="primary" type="submit" form="save-design-form">
          Konfiguration speichern
        </Button>
      </DialogActions>
    </CoreDialog>
  )
}
