import React from 'react'
import { Typography } from '@mui/material'
import { useItemProperties } from '@teamsesam/configurator-core'
import { ItemProperties } from '../../three/config'
import { LabelContainer } from '../LabelContainer'
import { getRollingDrawerWidth, rollingDrawerHeight } from '../../three/constants'
import { ItemOptionsProps } from './InteriorProperties'

export function RollingDrawerOptions(props: ItemOptionsProps) {
  const { item } = props
  const { columnWidth } = useItemProperties(item, [ItemProperties.ColumnWidth])
  return (
    <>
      <LabelContainer label="Breite">
        <Typography>{getRollingDrawerWidth(columnWidth)} cm</Typography>
      </LabelContainer>
      <LabelContainer label="Höhe">
        <Typography>{rollingDrawerHeight} cm</Typography>
      </LabelContainer>
    </>
  )
}
