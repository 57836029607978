import { ItemContext } from '@teamsesam/configurator-core'
import { ItemProperties } from '../config'
import * as THREE from 'three'
import { getPlinthPanelWidth, plateThickness, plinthPanelHeight, plinthPanelOffsetZ } from '../constants'

export const plinthPanel = (context: ItemContext) => {
  const { item, on, materials } = context

  const panel = new THREE.Mesh(new THREE.BoxGeometry(1, plinthPanelHeight, plateThickness), materials.shelfMaterial)
  item.add(panel)

  const update = () => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth) as number
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth) as number

    panel.scale.x = getPlinthPanelWidth(columnWidth)
    panel.position.z = (shelfDepth - plateThickness) / 2 - plinthPanelOffsetZ

    item.updateBoundingBox()
  }

  update()

  on.propertyChanged([ItemProperties.ColumnWidth, ItemProperties.ShelfDepth], update)

  on.materialsChanged((materials) => (panel.material = materials.shelfMaterial))
}
